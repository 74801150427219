import {
  Autocomplete,
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { COLORS, validacionesStatus } from "../../../json/StatusOptions";
import { postElemento } from "../../../utils/ApiCall";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { typeOfClient } from "../../../json/CampaignCreator";
import AddIcon from "@mui/icons-material/Add";
import { Currencies } from "../../../json/Currencies";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const RowCreator = ({ pathname, updateBoard }) => {
  const [open, setOpen] = useState(false);
  const [dataForm, setDataForm] = useState({
    elemento: "",
    typeOfClient: "",
    status: "",
    totalBill: "",
    currency: "",
    socialReason: "",
    table: "",
  });

  const selectedColor = COLORS[dataForm.type || dataForm.status] || "";

  const handleClose = () => setOpen(false);

  const handleDataForm = (e) => {
    setDataForm({
      ...dataForm,
      [e.target.name]: e.target.value,
    });
  };

  const handleStatus = (event, newValue) => {
    setDataForm({
      ...dataForm,
      status: newValue.value,
    });
  };

  const handleCurrency = (event, newValue) => {
    setDataForm({
      ...dataForm,
      currency: newValue,
    });
  };

  const handleTable = (event, newValue) => {
    setDataForm({
      ...dataForm,
      table: newValue,
    });
  };

  const handleTypeOfClient = (event, newValue) => {
    setDataForm({
      ...dataForm,
      typeOfClient: newValue.name,
    });
  };

  const currentMonthAndYear = () => {
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const actualDate = new Date();
    const actualMonth = actualDate.getMonth();
    const actualYear = actualDate.getFullYear();

    let monthName = meses[(actualMonth + 12) % 12];
    let monthAndYear = `Clientes Activos ${monthName} ${actualYear}`;

    return monthAndYear;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let postData;

    if (pathname === "/validaciones-cierre-aluna") {
      postData = {
        table: currentMonthAndYear(),
      };
    }
    await postElemento(postData, pathname);
    updateBoard(postData);
    setDataForm({
      elemento: "",
      typeOfClient: "",
      status: "",
      totalBill: "",
      currency: "",
      socialReason: "",
      table: "",
    });
  };

  function generarNombresClientesActivos() {
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre",
    ];

    const fechaActual = new Date();
    const resultado = [];

    for (let i = 0; i < 12; i++) {
      const mesActual = fechaActual.getMonth() - i;
      const añoActual = fechaActual.getFullYear();
      const ajusteAño = mesActual < 0 ? -1 : 0;

      let nombreMes = meses[(mesActual + 12) % 12];
      let nombreCompleto = `Clientes Activos ${nombreMes} ${
        añoActual + ajusteAño
      }`;

      resultado.push(nombreCompleto);
    }

    return resultado;
  }

  const opcionesMeses = generarNombresClientesActivos();

  return (
    <Box>
      <Button
        sx={{ padding: "4px 5px", fontSize: "13px" }}
        onClick={handleSubmit}
        startIcon={<AddIcon />}
      >
        Agregar Elemento
      </Button>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} component="form" onSubmit={handleSubmit}>
          <Typography variant="h3">Agregar Elemento</Typography>

          <div style={{ margin: "40px 0px" }}>
            {(pathname !== "/master-tag-roundler" ||
              pathname !== "/master-tag-aluna") && (
              <TextField
                id="standard-basic"
                label="Elemento"
                variant="standard"
                sx={{ width: "100%", marginBottom: "40px" }}
                onChange={handleDataForm}
                value={dataForm.elemento}
                name="elemento"
              />
            )}
            {pathname === "/validaciones-cierre-aluna" && (
              <div>
                <div style={{ display: "flex", marginBottom: "40px" }}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    options={opcionesMeses}
                    getOptionLabel={(option) => option}
                    sx={{ width: "100%", marginRight: "20px" }}
                    onChange={handleTable}
                    value={
                      opcionesMeses.find((tab) => tab === dataForm.table) ||
                      null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Tabla"
                        variant="standard"
                        value={dataForm.table}
                        name="table"
                      />
                    )}
                  />
                  <Autocomplete
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    options={typeOfClient}
                    getOptionLabel={(option) => option.name}
                    sx={{ width: "100%" }}
                    onChange={handleTypeOfClient}
                    value={
                      typeOfClient.find(
                        (type) => type.name === dataForm.typeOfClient
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Type of Client"
                        variant="standard"
                        value={dataForm.typeOfClient}
                        name="typeOfClient"
                      />
                    )}
                  />
                </div>

                <div style={{ display: "flex" }}>
                  <Autocomplete
                    disableClearable
                    disablePortal
                    id="combo-box-demo"
                    options={validacionesStatus}
                    getOptionLabel={(option) => option.value}
                    sx={{ width: "100%", marginRight: "20px" }}
                    onChange={handleStatus}
                    value={
                      validacionesStatus.find(
                        (stat) => stat.value === dataForm.status
                      ) || null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Estado"
                        variant="standard"
                        value={dataForm.status}
                        name="status"
                        sx={{
                          backgroundColor: selectedColor,
                          color: "white",
                        }}
                      />
                    )}
                    renderOption={(props, option) => (
                      <li
                        {...props}
                        style={{
                          backgroundColor: COLORS[option.value],
                          margin: "10px",
                          color: "white",
                        }}
                      >
                        {option.value}
                      </li>
                    )}
                  />

                  <TextField
                    type="number"
                    id="standard-basic"
                    label="Total a Facturar"
                    variant="standard"
                    sx={{
                      width: "100%",
                      marginBottom: "40px",
                    }}
                    onChange={handleDataForm}
                    value={dataForm.totalBill}
                    name="totalBill"
                  />
                </div>

                <div style={{ display: "flex" }}>
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={Currencies}
                    getOptionLabel={(curr) => curr}
                    sx={{
                      width: "100%",
                      marginRight: "20px",
                    }}
                    onChange={handleCurrency}
                    value={
                      Currencies.find((curr) => curr === dataForm.currency) ||
                      null
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Currency"
                        variant="standard"
                      />
                    )}
                  />

                  <TextField
                    id="standard-basic"
                    label="Razón Social"
                    variant="standard"
                    sx={{ width: "100%", marginBottom: "40px" }}
                    onChange={handleDataForm}
                    value={dataForm.socialReason}
                    name="socialReason"
                  />
                </div>
              </div>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button type="submit">Crear Elemento</Button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default RowCreator;
