import {
  Grid,
  ListItemButton,
  ListItemText,
  Menu,
  Tooltip,
} from "@mui/material";
import { useRef, useState, useEffect } from "react";
import { darken } from "@mui/system";
import { COLORS } from "../../../json/StatusOptions";
import { editBoardField } from "../../../utils/ApiCall";

const hexToRgb = (hex) => {
  let bigint = parseInt(hex.slice(1), 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;
  return `rgb(${r}, ${g}, ${b})`;
};

const ColumnStatus = ({
  value,
  row,
  selectionRow,
  field,
  columnWidth,
  columnPosition,
  optionsAmount,
  disabled,
  handleEditResponseChange,
  statusOption,
}) => {
  const [state, setState] = useState({
    openMenu: false,
    selectedValue: value,
    anchorEl: null,
  });

  const cellRef = useRef(null);
  const isMobile = window.innerWidth <= 600;

  useEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedValue: value,
    }));
  }, [value]);

  const handleClick = (event) => {
    if (disabled) return;

    setState((prevState) => ({
      ...prevState,
      openMenu: true,
      anchorEl: event.currentTarget,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      openMenu: false,
      anchorEl: null,
    }));
  };

  const handleSelectChange = (newValue) => {
    const targetRows = selectionRow.length ? selectionRow : [row.id];

    editBoardField(window.location.pathname, targetRows, field, newValue);
    targetRows.forEach((targetRow) =>
      handleEditResponseChange(newValue, targetRow, field)
    );

    setState((prevState) => ({
      ...prevState,
      selectedValue: newValue,
      openMenu: false,
      anchorEl: null,
    }));
  };

  const cellStyle = {
    backgroundColor: COLORS[state.selectedValue] || "rgb(196, 196, 196)",
    position: "relative",
    cursor: disabled ? "not-allowed" : "pointer",
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: columnWidth ? "900px" : optionsAmount === "low" ? "400px" : "700px",
    maxHeight: isMobile ? "600px" : "auto",
    overflowY: isMobile ? "scroll" : "visible",
    paddingRight: isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "49%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  return (
    <>
      <p
        onClick={handleClick}
        ref={cellRef}
        style={cellStyle}
        className="cellStyle"
      >
        <Tooltip title={state.selectedValue}>
          <span className="paragraph">{state.selectedValue}</span>
        </Tooltip>
      </p>

      <Menu
        anchorEl={state.anchorEl}
        open={state.openMenu}
        onClose={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            ...menuStyle,
            marginLeft: columnWidth ? "50px" : `${columnPosition}px`,
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <Grid container spacing={1}>
          {statusOption?.map((option) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              lg={4}
              xl={statusOption.length <= 3 ? 12 : 4}
              key={option.value}
            >
              <ListItemButton
                selected={state.selectedValue === option.value}
                onClick={() => handleSelectChange(option.value)}
                sx={{
                  color: "white",
                  backgroundColor: COLORS[option.value] || "white",
                  padding: "4px",
                  borderRadius: "2px",
                  "&.Mui-selected": {
                    backgroundColor: COLORS[option.value] || "white",
                    border: "2px solid var(--grayDark)",
                  },
                  "&:hover": {
                    backgroundColor: COLORS[option.value]
                      ? darken(hexToRgb(COLORS[option.value]), 0.2)
                      : darken("white", 0.2),
                  },
                }}
              >
                <ListItemText
                  primary={option.label}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                />
              </ListItemButton>
            </Grid>
          ))}
        </Grid>
      </Menu>
    </>
  );
};

export default ColumnStatus;
