export const validPathsForRowCreator = [
  "/tasks-aluna",
  "/prospecting-afiliado",
  "/info-trafico-afiliado",
  "/solicitud-campana",
  "/validaciones-cierre-aluna",
  "/it-request",
  "/master-tag-roundler",
  "/master-tag-aluna",
  "/crm",
  "/prospecting",
];
