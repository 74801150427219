import React, { useState } from "react";

const EditableFieldNumber = ({ value, row, api, onStateChange, field }) => {
  const [inputValue, setInputValue] = useState(value || "");

  const handleChange = (e) => {
    const newValue = e.target.value;

    setInputValue(newValue);

    api.setEditCellValue({
      id: row.id,
      field: field,
      value: newValue,
    });

    onStateChange(newValue);
  };

  return (
    <input
      type="number"
      value={inputValue}
      onChange={handleChange}
      style={{
        width: "100%",
        padding: "8px",
        outline: "none",
        border: "none",
      }}
      step="0.01"
    />
  );
};

export default EditableFieldNumber;
