import React, { useEffect } from "react";
import container from "../../../../styles/Global.module.css";
import { editBoardField, fetchData } from "../../../../utils/ApiCall.js";
import Threads from "../../../Threads/Threads.jsx";
import { solicitudCampanaStatus } from "../../../../json/StatusOptions.js";
import ColumnStatus from "../../../../components/global/fields/ColumnStatus.jsx";
import EditableField from "../../../../components/global/fields/EditableFieldNumber.jsx";
import EditableText from "../../../../components/global/fields/EditableText.jsx";
import SingleDuration from "../../../../components/global/fields/SingleDuration.jsx";
import EditableSelectMultiple from "../../../../components/global/fields/EditableSelectMultiple.jsx";
import EditableSelectCountry from "../../../../components/global/fields/EditableSelectCountry.jsx";
import { sortAlphabetically } from "../../../../json/CampaignCreator.js";
import { countries } from "../../../../json/Countries.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const SolicitudCampana = () => {
  const apiUrl = `${backendUrl}/api/solicitud-campana/items`;
  const apiService = `${backendUrl}/api/offer-service`;
  const [state, updateState] = useCustomState({
    groups: ["Nombre de campaña"],
    status: "Nombre de campaña",
    payAffiliate: "",
    items: [],
    selectionRow: [],
    trafficSources: [],
    categories: [],
    updateRows: [],
    columnsFilter: { id: false },
    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    pinnedColumns: {
      left: ["__check__", "threads"],
      right: [],
    },
  });

  useEffect(() => {
    const fetchApi = async () => {
      const resSources = await fetchData(`${apiService}/getSources`);
      const resCategories = await fetchData(`${apiService}/getCategories`);

      updateState({
        trafficSources: sortAlphabetically(resSources, "title"),
        categories: sortAlphabetically(resCategories, "title"),
      });
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterItemsAndSetGroups = (items) => {
    updateState({ items });
  };

  const controlEdit = async (params) => {
    let ids =
      state.selectionRow.length !== 0 ? state.selectionRow : [params.id];
    switch (params.field) {
      case "payAffiliate":
        await editBoardField(
          "/solicitud-campana",
          ids,
          params.field,
          state.payAffiliate
        );
        break;
      default:
        break;
    }
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    const updatedRowsNoTerminados = state.items.map((row) => {
      if (+row.id === +rowID) {
        return { ...row, [`${field}`]: newValue };
      }
      return row;
    });

    updateState({ items: updatedRowsNoTerminados });
  };

  const getGroupRows = (groupName) => {
    return groupName === "Nombre de campaña" ? state.items : [];
  };

  const updateBoard = (newData) => {
    const updatedRows = [...state.updateRows, newData];

    updateState({ updateRows: updatedRows });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 300,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Elemento",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"name"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.name}
        />
      ),
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "owner.username",
      headerName: "Persona que lo pide",
      width: 160,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => params.row.owner?.username,
    },
    {
      field: "status",
      headerName: "Estado",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"status"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={solicitudCampanaStatus}
        />
      ),
    },
    {
      field: "date",
      headerName: "Fecha de solicitud",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleDuration
          {...params}
          selectionRow={state.selectionRow}
          fieldKey="date"
        />
      ),
    },
    {
      field: "conversionType",
      headerName: "Tipo de Compra",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"conversionType"}
          handleEditResponseChange={handleEditResponseChange}
          options={["CPL", "CPI", "CPC", "CPM", "CPS", "CPA"]}
          getOptionLabelFunc={(client) => client}
          initialValue={params.row.conversionType}
          label="Tipo de Compra"
        />
      ),
    },
    {
      field: "landingPreview",
      headerName: "Landing Preview",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"landingPreview"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.landingPreview}
        />
      ),
    },
    {
      field: "affiliateName",
      headerName: "Afiliado que solicita",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"affiliateName"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.affiliateName}
          styleText={true}
        />
      ),
    },
    {
      field: "payAffiliate",
      headerName: "PAY Afiliado USD",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderEditCell: (params) => (
        <EditableField
          {...params}
          onStateChange={(value) => {
            updateState({ payAffiliate: value });
          }}
        />
      ),
      editable: "true",
    },
    {
      field: "country",
      headerName: "País",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectCountry
          {...params}
          field={"country"}
          handleEditResponseChange={handleEditResponseChange}
          options={countries}
          initialValue={params.row.country}
        />
      ),
    },
    {
      field: "vertical",
      headerName: "Vertical",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"vertical"}
          handleEditResponseChange={handleEditResponseChange}
          options={state.categories.map((item) => item.title)}
          getOptionLabelFunc={(vertical) => vertical}
          initialValue={params.row.vertical}
          label="Vertical"
        />
      ),
    },

    {
      field: "trafficSources",
      headerName: "Traffic Source",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"trafficSources"}
          handleEditResponseChange={handleEditResponseChange}
          options={state.trafficSources.map((item) => item.title)}
          getOptionLabelFunc={(traffic) => traffic}
          initialValue={params.row.trafficSources}
          label="Traffic Source"
        />
      ),
    },
    {
      field: "additionalComments",
      headerName: "Comentarios Adicionales",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"additionalComments"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.additionalComments}
        />
      ),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <BaseBoard
        columns={columns}
        groups={state.groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        updateBoard={updateBoard}
        updateRows={state.updateRows}
        setSelectionRow={(selectionRow) => updateState({ selectionRow })}
        selectionRow={state.selectionRow}
        controlEdit={controlEdit}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        viewFilter={state.viewFilter}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        pinnedColumns={state.pinnedColumns}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default SolicitudCampana;
