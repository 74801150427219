import { useState } from "react";

export const useCustomState = (initialState) => {
  const [state, setState] = useState(initialState);

  const updateState = (updates) => {
    setState((prevState) => ({
      ...prevState,
      ...updates,
    }));
  };

  return [state, updateState];
};
