import React from "react";
import container from "../../../../styles/Global.module.css";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { editBoardField, getStatus } from "../../../../utils/ApiCall.js";
import Threads from "../../../Threads/Threads.jsx";
import { prospectingStatus } from "../../../../json/StatusOptions.js";
import ColumnStatus from "../../../../components/global/fields/ColumnStatus.jsx";
import EditableText from "../../../../components/global/fields/EditableText.jsx";
import SingleDuration from "../../../../components/global/fields/SingleDuration.jsx";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ProspectingAfiliados = () => {
  const [state, updateState] = useCustomState({
    groups: [
      "Belén Suero",
      "Camila Velloso",
      "Carolina Racana",
      "Katalina Palomino",
      "Sara López Graciano",
    ],
    belenSuero: [],
    mariaCamila: [],
    carolinaRacana: [],
    katalinaPalomino: [],
    saraLopez: [],
    statusByParams: "Belén Suero",
    status: "Belén Suero",
    capEstimado: "",
    selectionRow: [],
    columnsFilter: { id: false },
    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    pinnedColumns: {
      left: ["__check__", "threads"],
      right: [],
    },
    updateRows: [],
  });

  const apiUrl = `${backendUrl}/api/prospecting-afiliado/items?owner=${state.status}`;

  const filterByOwner = (rows, status) => {
    let itemsFiltered = rows.filter((item) => {
      if (item.owner?.username === status) {
        return true;
      } else if (status === "Camila Velloso" && item.owner == null) {
        return true;
      }
      return false;
    });
    return itemsFiltered;
  };

  const filterItemsAndSetGroups = async (items) => {
    updateState({
      belenSuero: filterByOwner(await items, "Belén Suero"),
      mariaCamila: filterByOwner(items, "Camila Velloso"),
      carolinaRacana: filterByOwner(items, "Carolina Racana"),
      katalinaPalomino: filterByOwner(items, "Katalina Palomino"),
      saraLopez: filterByOwner(items, "Sara López Graciano"),
    });
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    let status = await getStatus(rowID, "prospecting-afiliado");

    const updateGroupState = (groupKey) => {
      updateState({
        [groupKey]: state[groupKey].map((row) =>
          +row.id === +rowID ? { ...row, [`${field}`]: newValue } : row
        ),
      });
    };

    switch (status) {
      case "Belén Suero":
        updateGroupState("belenSuero");
        break;
      case "Carolina Racana":
        updateGroupState("carolinaRacana");
        break;
      case "Katalina Palomino":
        updateGroupState("katalinaPalomino");
        break;
      case "Sara López Graciano":
        updateGroupState("saraLopez");
        break;
      default:
        updateGroupState("mariaCamila");
    }
  };

  const updateBoard = (newData) => {
    updateState({
      updateRows: [...state.updateRows, newData],
    });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "name",
      headerName: "Elemento",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"name"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.name}
        />
      ),
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "status",
      headerName: "Estado",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"status"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={prospectingStatus}
        />
      ),
    },
    {
      field: "date",
      headerName: "Fecha de último contacto",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleDuration
          {...params}
          selectionRow={state.selectionRow}
          fieldKey="date"
        />
      ),
    },
    {
      field: "actions",
      headerName: "Explicación explayada de acciones",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"actions"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.actions}
        />
      ),
    },
  ];

  const controlEdit = async (params) => {
    let ids =
      state.selectionRow.length !== 0 ? state.selectionRow : [params.id];
    switch (params.field) {
      case "date":
        await editBoardField(
          "/prospecting-afiliado",
          ids,
          params.field,
          state.capEstimado
        );
        break;
      default:
        break;
    }
  };

  const getGroupRows = (t) => {
    const groupMap = {
      "Belén Suero": state.belenSuero,
      "Camila Velloso": state.mariaCamila,
      "Carolina Racana": state.carolinaRacana,
      "Katalina Palomino": state.katalinaPalomino,
      "Sara López Graciano": state.saraLopez,
    };
    return groupMap[t] || [];
  };

  return (
    <div className={container.globalContainer}>
      <BaseBoard
        columns={columns}
        groups={state.groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        updateBoard={updateBoard}
        updateRows={state.updateRows}
        setSelectionRow={(selectionRow) => updateState({ selectionRow })}
        selectionRow={state.selectionRow}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        viewFilter={state.viewFilter}
        controlEdit={controlEdit}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        pinnedColumns={state.pinnedColumns}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default ProspectingAfiliados;
