import { useContext, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton, Tooltip, useMediaQuery } from "@mui/material";
import { UserContext } from "../../UserContext";
import { postMediaItem, deleteMediaItem } from "../../utils/ApiCall";
import NoRowsOverlay from "../../components/global/Loading";
import { FileUploader } from "react-drag-drop-files";
import excelimage from "../../assets/icons/excel_v2.svg";
import pdfimage from "../../assets/icons/pdf_v2.svg";
import zipimage from "../../assets/icons/zip_v2.svg";
import wordimage from "../../assets/icons/word_v2.svg";
import CustomFileUploader from "../../components/global/CustomFileUploader";
import { fileTypes } from "../../json/FileTypes";

const Media = ({
  id,
  media,
  backendUrl,
  handleImageClick,
  imgRefs,
  setItemMedia,
  itemMedia,
}) => {
  let pathname = window.location.pathname.replace("/", "");
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const { key, setKey } = useContext(UserContext);

  setKey(localStorage?.getItem("jwt"));

  const [files, setFiles] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);

  const handleUploadFile = async (file) => {
    setLoadingFile(true);
    const newFiles = Array.from(file);
    const fileJoined = Array.from(files).concat(newFiles);
    setFiles(fileJoined);
    const newMedia = itemMedia ? itemMedia.concat(newFiles) : newFiles;
    let response = await postMediaItem(key, newMedia, id, pathname);
    setItemMedia(response.data.media);
    setLoadingFile(false);
  };

  const handleDelete = async (e) => {
    const response = await deleteMediaItem(e, id, pathname);
    setItemMedia(response.data.media);
  };

  return (
    <div>
      <Box sx={{ padding: "25px" }}>
        <Box
          sx={{
            position: "relative",
            cursor: "pointer",
            marginBottom: "12px",
          }}
        >
          <FileUploader
            handleChange={handleUploadFile}
            name="file"
            types={fileTypes}
            multiple={true}
            label="Elegir archivos o arrastrar aqui"
            hoverTitle="Arrastrar aqui"
            children={<CustomFileUploader isMobile={isMobile} />}
          />
        </Box>
        {loadingFile && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(255, 255, 255, 0.7)",
              zIndex: 1000,
            }}
          >
            <NoRowsOverlay />
          </div>
        )}
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            position: "relative",
            gap: "40px",
            marginTop: "20px",
          }}
        >
          {media &&
            media.map((file) => {
              if (
                file.mime === "image/jpeg" ||
                file.mime === "image/png" ||
                file.mime === "image/bmp" ||
                file.mime === "image/x-png" ||
                file.mime === "image/gif" ||
                file.mime === "video/mp4"
              ) {
                return (
                  <div
                    style={{
                      width: "220px",
                      height: "132px",
                      backgroundColor: "white",
                      borderRadius: "4px",
                      border: "1px solid #dee2ff",
                      position: "relative",
                    }}
                    key={file.id}
                  >
                    <Tooltip title="Eliminar">
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          zIndex: "1000",
                          color: "var(--black)",
                          padding: "5px",
                          backgroundColor: "rgb(248, 247, 255)",
                          borderRadius: "4px",
                          margin: "5px",
                        }}
                        onClick={() => handleDelete(file.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                    <div style={{ height: "100%" }}>
                      <div
                        style={{
                          backgroundImage: `url("${backendUrl}${file.url}")`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          height: "100%",
                          cursor: "pointer",
                        }}
                        onLoad={(e) => {
                          imgRefs.current[file.id] = e.target;
                        }}
                        onClick={(e) => handleImageClick(e, file)}
                      ></div>
                    </div>
                    <div style={{ width: "100%" }}>
                      <Tooltip title={file.name}>
                        <p
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                            margin: "10px 0 20px 0",
                          }}
                        >
                          {file.name}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                    style={{
                      width: "220px",
                      height: "132px",
                      backgroundColor:
                        file.mime ===
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                          ? "rgb(35, 127, 76)"
                          : file.mime === "application/pdf"
                          ? "rgb(250, 15, 0)"
                          : file.mime === "application/zip"
                          ? "rgb(255, 204, 0)"
                          : file.mime === "text/html"
                          ? "rgb(35, 104, 196)"
                          : "",
                      borderRadius: "4px",
                      border: "1px solid #dee2ff",
                      position: "relative",
                    }}
                    key={file.id}
                  >
                    <Tooltip title="Eliminar">
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: "0",
                          right: "0",
                          zIndex: "1000",
                          color: "var(--black)",
                          padding: "5px",
                          backgroundColor: "rgb(248, 247, 255)",
                          borderRadius: "4px",
                          margin: "5px",
                        }}
                        onClick={() => handleDelete(file.id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <div
                      style={{
                        height: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          file.mime ===
                          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                            ? excelimage
                            : file.mime === "application/pdf"
                            ? pdfimage
                            : file.mime === "application/zip"
                            ? zipimage
                            : wordimage
                        }
                        alt=""
                        style={{
                          height: "70%",
                          width: "70%",
                          objectFit: "contain",
                          objectPosition: "center",
                          cursor: "pointer",
                        }}
                        onLoad={(e) => {
                          imgRefs.current[file.id] = e.target;
                        }}
                        onClick={(e) => handleImageClick(e, file)}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <Tooltip title={file.name}>
                        <p
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                            maxWidth: "100%",
                            margin: "10px 0 20px 0",
                          }}
                        >
                          {file.name}
                        </p>
                      </Tooltip>
                    </div>
                  </div>
                );
              }
            })}
        </div>
      </Box>
    </div>
  );
};

export default Media;
