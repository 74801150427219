import { Box, IconButton } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {
  DeleteOutlineOutlined,
  Edit,
  PushPinOutlined,
} from "@mui/icons-material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import React from "react";

import fotoPerfil from "../../assets/icons/perfilionvo.png";

import styles from "../../styles/Threads.module.css";

const HeaderContent = ({
  open,
  thread,
  formattedDate,
  handleClick,
  anchorEl,
  handleClose,
  handleAnchor,
  threadID,
  handleFormDeleteThread,
  handleFormEditThread,
  userId,
}) => {
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  return (
    <div className={styles.cardWrap}>
      <div id={"thread" + thread.id} className={styles.user}>
        <img
          src={
            thread?.user?.avatar
              ? `${backendUrl}${thread?.user?.avatar?.url}`
              : fotoPerfil
          }
          style={{
            width: "40px",
            height: "40px",
            borderRadius: "50px",
            objectFit: "cover",
            objectPosition: "center",
          }}
          alt=""
        />
        <h2 className={styles.username}>{thread?.user?.username}</h2>
      </div>
      <div className={styles.dateWrap}>
        <ScheduleIcon
          style={{
            marginRight: "6px",
            color: "var(--gray)",
            fontSize: "18px",
          }}
        />
        <p className={styles.dateTitle}>{formattedDate}</p>

        <React.Fragment>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <IconButton
              onClick={(event) => handleClick(event, thread)}
              size="small"
              sx={{ ml: 2, padding: "0px" }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MoreHorizIcon />
            </IconButton>
          </Box>
          <Menu
            anchorEl={anchorEl}
            id="account-menu"
            open={open}
            onClose={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                // filter:
                //   "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                border: "1px solid #dee2ff",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                  border: "1px solid #dee2ff",
                  borderRight: "0px",
                  borderBottom: "0px",
                },
              },
            }}
            transformOrigin={{
              horizontal: "right",
              vertical: "top",
            }}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          >
            <MenuItem
              onClick={() => handleAnchor(threadID)}
              style={{ color: "var(--gray)" }}
            >
              <PushPinOutlined
                style={{
                  marginRight: "10px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "var(--gray)",
                }}
              />{" "}
              {threadID?.anchored === true
                ? "Quitar de la parte superior"
                : "Anclar a la parte superior"}
            </MenuItem>

            {+threadID?.user?.id === +userId ? (
              <MenuItem
                style={{ color: "var(--gray)" }}
                onClick={() => handleFormEditThread(threadID.id)}
              >
                <Edit
                  style={{
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "var(--gray)",
                  }}
                />{" "}
                Editar thread
              </MenuItem>
            ) : null}

            {+threadID?.user?.id === +userId ? (
              <MenuItem
                onClick={() => handleFormDeleteThread(threadID)}
                style={{ color: "var(--gray)" }}
              >
                <DeleteOutlineOutlined
                  style={{
                    marginRight: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "var(--gray)",
                  }}
                />{" "}
                Eliminar thread
              </MenuItem>
            ) : null}
          </Menu>
        </React.Fragment>
      </div>
    </div>
  );
};
export default HeaderContent;
