import React, { useEffect } from "react";
import container from "../../../../styles/Global.module.css";
import { fetchData } from "../../../../utils/ApiCall.js";
import Threads from "../../../Threads/Threads.jsx";
import { infoTraficoAfiliadoStatus } from "../../../../json/StatusOptions.js";
import ColumnStatus from "../../../../components/global/fields/ColumnStatus.jsx";
import EditableText from "../../../../components/global/fields/EditableText.jsx";
import EditableSelectMultiple from "../../../../components/global/fields/EditableSelectMultiple.jsx";
import EditableSelectCountry from "../../../../components/global/fields/EditableSelectCountry.jsx";
import { sortAlphabetically } from "../../../../json/CampaignCreator.js";
import { continents } from "../../../../json/Countries.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const InfoTraficoAfiliado = () => {
  const apiUrl = `${backendUrl}/api/info-trafico-afiliado/items`;
  const apiService = `${backendUrl}/api/offer-service`;

  const [state, updateState] = useCustomState({
    status: "Afiliado",
    selectionRow: [],
    updateRows: [],
    affiliatesManager: [],
    trafficSources: [],
    items: [],
    columnsFilter: { id: false },
    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    pinnedColumns: {
      left: ["__check__", "threads"],
      right: [],
    },
  });

  useEffect(() => {
    const fetchApi = async () => {
      const resSources = await fetchData(`${apiService}/getSources`);
      updateState({ trafficSources: sortAlphabetically(resSources, "title") });
    };

    fetchApi();
  }, [apiService, updateState]);

  useEffect(() => {
    const usernamesSet = new Set();
    state.items.forEach((item) => {
      usernamesSet.add(item.owner.username);
    });
    const usernamesUnicos = [...usernamesSet];

    updateState({ affiliatesManager: usernamesUnicos });
  }, [state.items, updateState]);

  const handleEditResponseChange = (newValue, rowID, field) => {
    updateState({
      items: state.items.map((row) =>
        +row.id === +rowID ? { ...row, [field]: newValue } : row
      ),
    });
  };

  const updateBoard = (newData) => {
    const updatedRows = [...state.updateRows, newData];

    updateState({ updateRows: updatedRows });
  };

  const getGroupRows = (groupName) =>
    groupName === "Afiliado" ? state.items : [];

  const filterItemsByStatus = (items, status, condition) =>
    condition
      ? items?.filter((item) => item.status === status)
      : items?.filter((item) => item.status !== status);

  const filterItemsAndSetGroups = (items) => {
    updateState({ items: filterItemsByStatus(items, "Afiliado", false) });
  };
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
    },
    {
      field: "name",
      headerName: "Elemento",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"name"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.name}
        />
      ),
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "owner.username",
      headerName: "Affiliate Manager",
      width: 160,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: state.affiliatesManager,
      valueGetter: (params) => params.row.owner.username,
    },
    {
      field: "status",
      headerName: "Estado",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"status"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={infoTraficoAfiliadoStatus}
        />
      ),
    },
    {
      field: "vertical",
      headerName: "Verticales",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"vertical"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.vertical}
        />
      ),
    },
    {
      field: "country",
      headerName: "Geos Habilitadas",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectCountry
          {...params}
          field={"country"}
          handleEditResponseChange={handleEditResponseChange}
          options={continents}
          initialValue={params.row.country}
        />
      ),
    },
    {
      field: "conversionType",
      headerName: "Tipo de Compra",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"conversionType"}
          handleEditResponseChange={handleEditResponseChange}
          options={["CPL", "CPI", "CPC", "CPM", "CPS", "CPA"]}
          getOptionLabelFunc={(client) => client}
          initialValue={params.row.conversionType}
          label="Tipo de Compra"
        />
      ),
    },
    {
      field: "trafficSources",
      headerName: "Traffic Source",
      width: 140,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"trafficSources"}
          handleEditResponseChange={handleEditResponseChange}
          options={state.trafficSources.map((item) => item.title)}
          getOptionLabelFunc={(traffic) => traffic}
          initialValue={params.row.trafficSources}
          label="Traffic Source"
        />
      ),
    },
    {
      field: "wishList",
      headerName: "Wishlist de campañas",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"wishList"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.wishList}
        />
      ),
    },
    {
      field: "runningCampaigns",
      headerName: "Campañas que corre",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"runningCampaigns"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.runningCampaigns}
        />
      ),
    },
    {
      field: "additionalComments",
      headerName: "Comentarios Adicionales",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"additionalComments"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.additionalComments}
        />
      ),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <BaseBoard
        columns={columns}
        groups={["Afiliado"]}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        updateBoard={updateBoard}
        updateRows={state.updateRows}
        setSelectionRow={(selectionRow) => updateState({ selectionRow })}
        selectionRow={state.selectionRow}
        viewFilter={state.viewFilter}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        pinnedColumns={state.pinnedColumns}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default InfoTraficoAfiliado;
