import * as React from "react";
import { useMemo } from "react";
import Button from "@mui/material/Button";
import LinkIcon from "@mui/icons-material/Link";
import { fetchData, postCapAffise } from "../../../../utils/ApiCall";
import container from "../../../../styles/Global.module.css";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import Cap from "../../../../components/aluna/Cap.jsx";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, ThemeProvider, createTheme } from "@mui/material";
import dayjs from "dayjs";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const PanelDeCaps = () => {
  const apiUrl = `${backendUrl}/api/offer-service/getDataPanelCaps`;

  const [state, updateState] = useCustomState({
    refreshState: false,
    status: "Panel de caps",
    groups: ["Panel de caps"],
    items: [],
    cap: "",
    timeframe: "",

    date: new Date().toISOString(),
    first: "01",
    dateFrom: new Date().toISOString().slice(0, 8).concat("01"),
    dateTo: new Date().toISOString().slice(0, 10),

    isSearching: false,

    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    columnsFilter: { id: false },

    edit: localStorage?.groups?.includes("afiliacion") ? true : false,
  });

  const filterItemsAndSetGroups = (items) => {
    updateState({ items });
  };

  const getGroupRows = (groupName) => {
    return groupName === "Panel de caps" ? state.items : [];
  };

  const columns = useMemo(
    () => [
      {
        field: "id",
        headerName: "ID",
        width: 70,
        headerAlign: "center",
        align: "center",
      },
      {
        field: "link",
        headerName: "Link aff.",
        width: 100,
        renderCell: (rowData) => (
          <Button href={rowData.value} target="_blank">
            <LinkIcon style={{ color: "black" }} />
          </Button>
        ),
      },
      {
        field: "offerID",
        headerName: "Offer ID",
        width: 100,
      },
      {
        field: "offerTitle",
        headerName: "Offer",
        width: 180,
      },
      {
        field: "affiliateTitle",
        headerName: "Titulo Afiliado",
        width: 120,
      },
      {
        field: "idAffiliate",
        headerName: "ID Afiliado",
        width: 100,
      },
      {
        field: "affiliateManager",
        headerName: "Manager de Afiliado",
        width: 160,
        type: "singleSelect",
        valueOptions: [
          "Katalina Palomino",
          "Sara López Graciano",
          "Inés Spitaleri",
          "Carolina Racana",
          "Belén Suero",
        ],
      },
      {
        field: "clicks",
        headerName: "Clicks",
        width: 120,
      },
      {
        field: "pendingQTY",
        headerName: "Pen Qty",
        width: 120,
      },
      {
        field: "declineQTY",
        headerName: "Dec Qty",
        width: 120,
      },
      {
        field: "cr",
        headerName: "CR",
        width: 120,
      },
      {
        field: "cap_total_offer",
        headerName: "Cap total offer",
        width: 160,
      },
      {
        field: "cap_total_offer_timeframe",
        headerName: "Cap total offer timeframe",
        width: 160,
      },
      {
        field: "cap",
        headerName: "Cap Aff",
        width: 80,
        headerAlign: "center",
        align: "center",

        renderEditCell: (params) => (
          <Cap
            {...params}
            onCapChange={(value) => updateState({ cap: value })}
            onTimeframeChange={(value) => updateState({ timeframe: value })}
          />
        ),
        editable: true,
      },
      {
        field: "timeframe",
        headerName: "Cap timeframe",
        width: 120,
        editable: true,
        editMode: "row",
        type: "singleSelect",
        valueOptions: [
          { value: "day", label: "day" },
          { value: "month", label: "month" },
          { value: "all", label: "all" },
        ],
      },
      {
        field: "total_conversion",
        headerName: "Total Conversion",
        width: 160,
        type: "number",
      },
      {
        field: "cap_total_assigned",
        headerName: "Total cap asignado",
        width: 160,
        type: "number",
      },

      {
        field: "cap_remaining",
        headerName: "Remaining Cap",
        width: 120,
        editMode: "row",
        type: "number",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const controlEdit = async (params) => {
    let oldCap = params.row.cap ?? 0;
    let differenceCaps = parseInt(state.cap) - oldCap;

    let cap_total_assigned = params.row.cap_total_assigned + differenceCaps;
    let cap_remaining = params.row.cap_remaining - differenceCaps;

    updateState({
      items: state.items.map((row) =>
        row.offerID === params.row.offerID &&
        row.idAffiliate === params.row.idAffiliate
          ? {
              ...row,
              cap: state.cap,
              timeframe: state.timeframe,
              cap_total_assigned,
              cap_remaining,
            }
          : row.offerID === params.row.offerID
          ? { ...row, cap_total_assigned, cap_remaining }
          : row
      ),
    });

    await postCapAffise(
      state.cap,
      state.timeframe,
      [params.row.idAffiliate],
      params.row.offerID
    );

    updateState({ refreshState: true });
  };

  const clearSearchResults = () => {
    updateState({ items: [], isSearching: false });
  };

  const handleSearchByDate = async () => {
    try {
      clearSearchResults();
      updateState({ isSearching: true });
      const formattedDateFrom = state.dateFrom
        ? dayjs(state.dateFrom).format("YYYY-MM-DD")
        : "";
      const formattedDateTo = state.dateTo
        ? dayjs(state.dateTo).format("YYYY-MM-DD")
        : "";

      const searchUrl = `${apiUrl}?dateFrom=${formattedDateFrom}&dateTo=${formattedDateTo}`;

      const data = await fetchData(searchUrl);

      updateState({ items: data, isSearching: false });
    } catch (error) {
      console.error("Error al buscar datos:", error);
      updateState({ isSearching: false });
    }
  };

  return (
    <div className={container.globalContainer}>
      <Box
        sx={{
          maxWidth: "600px",
          margin: "10px 0px 20px 0px",
        }}
      >
        <p>Date Range</p>
        <Box sx={{ display: "flex" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateRangePicker
              localeText={{
                start: `${state.dateFrom}`,
                end: `${state.dateTo}`,
              }}
              sx={{ backgroundColor: "white", marginRight: "10px" }}
              onChange={(newValue) => {
                if (newValue[0]) updateState({ dateFrom: newValue[0] });
                if (newValue[1]) updateState({ dateTo: newValue[1] });
              }}
              disableFuture
            />
          </LocalizationProvider>
          <ThemeProvider theme={theme}>
            <Button
              variant="outlined"
              onClick={handleSearchByDate}
              disabled={state.isSearching}
            >
              Generar
            </Button>
          </ThemeProvider>
        </Box>
      </Box>

      <BaseBoard
        columns={columns}
        groups={state.groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        refreshState={state.refreshState}
        controlEdit={controlEdit}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        viewFilter={state.viewFilter}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        checkboxSelection={false}
        isLoadingRows={state.isSearching}
      />
    </div>
  );
};

export default PanelDeCaps;
