import { useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
  menuClasses,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import { menuItems } from "../../json/SideBarItems";

const themes = {
  light: {
    sidebar: {
      backgroundColor: `#240046 !important`,
      color: `#e0aaff`,
    },
    menu: {
      menuContent: `#3c096c !important`,
      icon: `#d8b4e2`,
      hover: {
        backgroundColor: "#5a189a",
        color: "#e0aaff",
      },
      disabled: {
        color: "#9fb6cf",
      },
      active: {
        color: "#9fb6cf",
      },
    },
  },
  dark: {
    sidebar: {
      backgroundColor: `#1F2A40 !important`,
      color: "#8ba1b7",
    },
    menu: {
      menuContent: "#08440",
      icon: "#59d0ff",
      hover: {
        backgroundColor: "#00458b",
        color: "#b6c8d9",
      },
      disabled: {
        color: "#3e5e7e",
      },
    },
  },
};

const SideBar = ({ openMenu, setOpenMenu }) => {
  const { collapsed } = useProSidebar();
  const [state, setState] = useState({
    theme: "light",
    ionvoMenuOpen: false,
    menuOpen: menuItems[0].name,
  });
  const location = useLocation();
  const role = localStorage?.getItem("role");
  const menuItemStyles = {
    root: {
      fontSize: "13px",
      fontWeight: 400,
    },

    subMenuContent: () => ({
      backgroundColor: themes[state.theme].menu.menuContent,
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes[state.theme].menu.disabled.color,
      },
      "&:hover": {
        backgroundColor: themes[state.theme].menu.hover.backgroundColor,
        color: themes[state.theme].menu.hover.color,
      },
    },
  };

  const styles = {
    menuLink: {
      color: "#e0aaff",
    },
    menuItem: {
      overflow: "hidden",
      padding: `${collapsed ? "0px 20px 0px 20px" : "0px 20px 0px 40px"}`,
    },
    menuItemActive: {
      backgroundColor: "#5a189a",
      overflow: "hidden",
      padding: `${collapsed ? "0px 20px 0px 20px" : "0px 20px 0px 40px"}`,
    },
    subMenuItem: {
      overflow: "hidden",
      padding: `${collapsed ? "0px 20px 0px 40px" : "0px 20px 0px 60px"}`,
    },
    subMenuItemActive: {
      backgroundColor: "#5a189a",
      overflow: "hidden",
      padding: `${collapsed ? "0px 20px 0px 40px" : "0px 20px 0px 60px"}`,
    },
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const sidebarWidth = isMobile ? "100%" : "260px";

  const handleMenu = (name) => {
    setState((prevState) => ({
      ...prevState,
      ionvoMenuOpen: !prevState.ionvoMenuOpen,
      menuOpen: name,
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        height: "auto",
        caretColor: "transparent",
      }}
    >
      <Sidebar
        breakPoint="lg"
        backgroundColor={themes[state.theme].sidebar.backgroundColor}
        rootStyles={{
          color: themes[state.theme].sidebar.color,
        }}
        defaultCollapsed={false}
        width={sidebarWidth}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          <div style={{ flex: 1 }}>
            <Menu menuItemStyles={menuItemStyles}>
              <div
               
              >
                <img src="/navidad4-ionvo.gif" alt="" width={"100%"} />
              </div>
              {role &&
                menuItems.map((element, index) =>
                  element.roles.includes(role) ? (
                    <SubMenu
                      style={styles}
                      label={element.name}
                      open={state.menuOpen === element.name ? true : false}
                      onClick={() => handleMenu(element.name)}
                      key={index}
                    >
                      {element.item.map((el) =>
                        (el.subtitle?.length > 0) & el.roles.includes(role) ? (
                          <SubMenu key={el.title} label={el.title}>
                            {el.subtitle.map((sub) =>
                              sub.roles.includes(role) ? (
                                <Link
                                  to={sub.to}
                                  style={styles.menuLink}
                                  key={sub.to}
                                >
                                  <MenuItem
                                    style={
                                      location.pathname.includes(sub.to)
                                        ? styles.subMenuItemActive
                                        : styles.subMenuItem
                                    }
                                  >
                                    {sub.subTitle}
                                  </MenuItem>
                                </Link>
                              ) : null
                            )}
                          </SubMenu>
                        ) : el.roles.includes(role) ? (
                          <Link to={el.to} style={styles.menuLink} key={el.to}>
                            <MenuItem
                              style={
                                location.pathname.includes(el.to)
                                  ? styles.menuItemActive
                                  : styles.menuItem
                              }
                            >
                              {el.title}
                            </MenuItem>
                          </Link>
                        ) : null
                      )}
                    </SubMenu>
                  ) : null
                )}
            </Menu>
            <Menu></Menu>
          </div>
        </div>
      </Sidebar>
    </div>
  );
};

export default SideBar;
