import React, { useContext, useEffect } from "react";
import container from "../../../../styles/Global.module.css";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import {
  editOfferAffise,
  editBoardField,
  postCapAffise,
  getStatus,
  duplicateRowsMasterBranding,
} from "../../../../utils/ApiCall";
import Threads from "../../../Threads/Threads";
import { UserContext } from "../../../../UserContext";
import { Currencies } from "../../../../json/Currencies";
import { Button, ThemeProvider, Tooltip, createTheme } from "@mui/material";
import Cap from "../../../../components/aluna/Cap.jsx";
import Duration from "../../../../components/global/fields/Duration.jsx";
import { offerStatusMasterBranding } from "../../../../json/StatusOptions";
import { useLocation } from "react-router-dom";
import ColumnStatus from "../../../../components/global/fields/ColumnStatus.jsx";
import EditableSelect from "../../../../components/global/fields/EditableSelect.jsx";
import {
  formatos,
  meses,
  plataformas,
  testOptions,
  tiposContratacion,
} from "../../../../json/CampaignCreator.js";
import EditableText from "../../../../components/global/fields/EditableText.jsx";
import EditableSelectMultiple from "../../../../components/global/fields/EditableSelectMultiple.jsx";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import SingleUserSelect from "../../../../components/global/fields/SingleUserSelect";
import UsersSelect from "../../../../components/global/fields/UsersSelect.jsx";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const theme = createTheme({
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
  },
  typography: {
    fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
  },
});

const AlunaMasterBranding = () => {
  const location = useLocation();
  const pathname = location.pathname;
  const apiUrl = `${backendUrl}/api/master-branding/items`;
  const apiUsers = `${backendUrl}/api/users`;

  const [state, updateState] = useCustomState({
    status: "Offer ID",
    offerID: [],
    completed: [],
    payoutAceptado: "",
    cap: "",
    timeframe: "",
    capMensual: "",
    revenueMonedaAnunciante: "",
    windowWidth: window.innerWidth,
    posting: false,
    groups: ["Offer ID", "Completed"],
    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    columnsFilter: { id: false },
    pinnedColumns: {
      left: ["__check__", "offerID", "threads", "offerTitle"],
      right: [],
    },
    position: [0],
    selectedView: "tabla-principal",
    refreshState: false,
    selectionRow: [],
    updateRows: [],
    users: [
      "Ines Spitaleri",
      "Carolina Racana",
      "Katalina Palomino",
      "Sara Lopez",
      "Belén Suero",
      "Ignacio Currás",
    ],
  });

  const { key, setKey } = useContext(UserContext);
  const isMobile = state.windowWidth <= 600;

  setKey(localStorage?.getItem("jwt"));

  useEffect(() => {
    const fetchApi = async () => {
      const resUsers = await fetch(apiUsers);
      const data = await resUsers.json();

      updateState({ users: data });
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function OptionsFromColumns(array, attribute) {
    const valoresUnicos = new Set();
    for (const obj of array) {
      const valor = obj[attribute];
      if (valor !== undefined) {
        valoresUnicos.add(valor);
      }
    }
    return Array.from(valoresUnicos);
  }

  const filterByStatus = (rows, status) => {
    const filters = {
      "Offer ID": (item) => item.offerStatus !== "COMPLETED",
      Completed: (item) => item.offerStatus === "COMPLETED",
    };

    return rows.filter(filters[status]);
  };

  const filterItemsAndSetGroups = async (items) => {
    updateState({
      offerID: filterByStatus(items, "Offer ID"),
      completed: filterByStatus(items, "Completed"),
    });
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    let status;
    if (field !== "offerStatus") {
      status = await getStatus(rowID, "master-branding");
    } else {
      status = newValue;
    }

    if (status === "Completed") {
      const updatedRowsInactives = state.completed.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      updateState({ completed: updatedRowsInactives });
    } else {
      const updatedRowsOfferID = state.offerID.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      updateState({ offerID: updatedRowsOfferID });
    }
  };

  const mapFilterOptions = (options) => {
    const filterOptions = options.map((option) => {
      return option.label;
    });
    return filterOptions;
  };

  const columns = [
    {
      field: "id",
      headerName: " ID",
      width: 70,
      headerAlign: "center",
      align: "center",
      visible: "false",
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "offerID",
      headerName: "Offer ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "offerTitle",
      headerName: "Elemento",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "affiseLink",
      headerName: "Affise Link",
      width: 200,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Tooltip title={params.value}>
            <a
              href={params.value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "var(--blueFour)" }}
            >
              {params.value}
            </a>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "responsibles",
      headerName: "Responsables",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <UsersSelect
          {...params}
          users={state.users}
          field={"responsibles"}
          handleEditResponseChange={handleEditResponseChange}
        />
      ),
    },
    {
      field: "owner.username",
      headerName: "Owner",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleUserSelect
          {...params}
          users={state.users.filter((user) =>
            [
              "Felicitas Casado",
              "Maria Camila Arango",
              "Agustina Iemmi",
              "Natalia Illera",
              "Laura De La Cruz",
            ].includes(user.username)
          )}
          field={"owner"}
          handleEditResponseChange={handleEditResponseChange}
        />
      ),
    },
    {
      field: "offerStatus",
      headerName: "Estado",
      width: 180,
      cellClassName: "no-padding",
      headerAlign: "center",
      type: "singleSelect",
      valueOptions: mapFilterOptions(offerStatusMasterBranding),

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"offerStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={offerStatusMasterBranding}
        />
      ),
    },
    {
      field: "duration",
      headerName: "Duración",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <Duration {...params} selectionRow={state.selectionRow} />
      ),
    },
    {
      field: "conversionType",
      headerName: "Conversion Type",
      width: 160,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"conversionType"}
          handleEditResponseChange={handleEditResponseChange}
          options={["CPC", "CPM", "CPSE", "CPV"]}
          spanText={params.row.conversionType}
          initialValue={params.row.conversionType}
          label="Conversion Type"
        />
      ),
    },
    {
      field: "cap",
      headerName: "Cap",
      width: 80,
      headerAlign: "center",
      align: "center",

      renderEditCell: (params) => (
        <Cap
          {...params}
          onCapChange={(value) => updateState({ cap: value })}
          onTimeframeChange={(value) => updateState({ timeframe: value })}
        />
      ),
      editable: true,
    },
    {
      field: "timeframe",
      headerName: "Timeframe",
      width: 140,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"timeframe"}
          handleEditResponseChange={handleEditResponseChange}
          options={["Month", "Day", "Open"]}
          spanText={
            params.row.timeframe === "day" || params.row.timeframe === "Day" ? (
              <p className="cell-data">Daily</p>
            ) : params.row.timeframe === "month" ||
              params.row.timeframe === "Month" ? (
              <p className="cell-data">Monthly</p>
            ) : (
              <p className="cell-data">{params.row.timeframe}</p>
            )
          }
          initialValue={params.row.timeframe}
          label="Timeframe"
        />
      ),
    },
    {
      field: "trafficSources",
      headerName: "Traffic Sources Allowed",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"trafficSources"}
          handleEditResponseChange={handleEditResponseChange}
          options={formatos.map((opt) => opt.name)}
          getOptionLabelFunc={(opt) => opt}
          initialValue={params.row.trafficSources}
          label="Traffic Sources Allowed"
        />
      ),
    },
    {
      field: "platform",
      headerName: "Plataforma",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"platform"}
          handleEditResponseChange={handleEditResponseChange}
          options={plataformas.map((opt) => opt)}
          getOptionLabelFunc={(opt) => opt}
          initialValue={params.row.platform}
          label="Plataforma"
        />
      ),
    },
    {
      field: "advertiserName",
      headerName: "Advertiser",
      width: 160,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: OptionsFromColumns(state.offerID, "advertiserName"),
      cellClassName: "cell-content",
      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip title={params.value}>
            <p className="cell-data">{params.value}</p>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "typeOfContract",
      headerName: "Tipo de contratación",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"typeOfContract"}
          handleEditResponseChange={handleEditResponseChange}
          options={tiposContratacion.map((opt) => opt)}
          spanText={params.row.typeOfContract}
          initialValue={params.row.typeOfContract}
          label="Tipo de contratación"
        />
      ),
    },
    {
      field: "targeting",
      headerName: "Targeting",
      width: 300,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"targeting"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.targeting}
        />
      ),
    },
    {
      field: "test",
      headerName: "¿Test?",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"test"}
          handleEditResponseChange={handleEditResponseChange}
          options={testOptions.map((opt) => opt.name)}
          spanText={params.row.test === true ? "Sí" : "No"}
          initialValue={params.row.test === true ? "Sí" : "No"}
          label="¿Test?"
        />
      ),
    },
    {
      field: "billingMonth",
      headerName: "Mes de Facuración",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"billingMonth"}
          handleEditResponseChange={handleEditResponseChange}
          options={meses.map((opt) => opt)}
          spanText={params.row.billingMonth}
          initialValue={params.row.billingMonth}
          label="Mes de Facturación"
        />
      ),
    },

    {
      field: "cumplimiento",
      headerName: "Cumplimiento",
      width: 120,
      headerAlign: "center",
      align: "center",
      valueGetter: (params) => {
        if (
          params.row.timeframe !== "month" ||
          params.row.pendingQty === 0 ||
          params.row.pendingQty === null ||
          params.row.cap === 0
        ) {
          return null;
        }
        let cumplimiento = (params.row.pendingQty / params.row.cap) * 100;
        return `${cumplimiento.toFixed(2)}%`;
      },
    },

    {
      field: "revenueUSD",
      headerName: "Revenue USD",
      width: 120,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "revenueMAnunciante",
      headerName: "Revenue Moneda Anunciante",
      width: 180,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "monedaAnunciante",
      headerName: "Moneda Anunciante",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"monedaAnunciante"}
          handleEditResponseChange={handleEditResponseChange}
          options={Currencies}
          spanText={<p className="cell-data">{params.value}</p>}
          initialValue={params.row.monedaAnunciante}
          label="Moneda Anunciante"
          offerID={params.row.offerID}
          backendUrl={backendUrl}
          clave={key}
        />
      ),
    },
    {
      field: "payoutUSD",
      headerName: "Payout USD",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "totalRevenueUSD",
      headerName: "Revenue Total USD",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "totalPayoutUSD",
      headerName: "Payout Total USD",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "totalProfitUSD",
      headerName: "Profit Total USD",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
  ];

  const controlEdit = async (params) => {
    let ids =
      state.selectionRow.length !== 0 ? state.selectionRow : [params.id];
    switch (params.field) {
      case "payoutAceptado":
        await editBoardField(
          "/master-branding",
          ids,
          params.field,
          state.payoutAceptado
        );
        updateState({ refreshState: true });
        break;
      case "estimadoCapMensual":
        await editBoardField(
          "/master-branding",
          ids,
          params.field,
          state.capMensual
        );
        updateState({ refreshState: true });
        break;
      case "cap":
        if (params.row.timeframe) {
          await postCapAffise(
            state.cap,
            params.row.timeframe,
            [],
            params.row.offerID,
            pathname.slice(1)
          );
          updateState({ refreshState: true });
        }
        break;
      case "revenueMAnunciante":
        const dataOffer = {
          revenueMonedaAnunciante: state.revenueMonedaAnunciante,
          id: params.row.offerID,
        };
        await editOfferAffise(dataOffer);
        updateState({ refreshState: true });
        break;
      default:
        break;
    }
  };

  const getGroupRows = (t) => {
    return t === "Offer ID"
      ? state.offerID
      : t === "Completed"
      ? state.completed
      : [];
  };

  const updateBoard = (newData) => {
    const updatedRows = [...state.updateRows, newData];

    updateState({ updateRows: updatedRows });
  };

  const duplicateRows = async () => {
    updateState({ posting: true });
    const rows = state.offerID.filter((item) =>
      state.selectionRow.includes(item.id)
    );
    await duplicateRowsMasterBranding(rows);
    updateState({
      refreshState: true,
      posting: false,
    });
  };

  return (
    <div
      className={container.globalContainer}
      style={{ padding: isMobile ? "5px 10px" : "10px 30px" }}
    >
      <ThemeProvider theme={theme}>
        <Button
          variant="outlined"
          onClick={duplicateRows}
          disabled={
            state.posting
              ? state.posting
              : state.selectionRow.length > 0
              ? false
              : true
          }
        >
          Duplicar row"
        </Button>
      </ThemeProvider>

      <BaseBoard
        columns={columns}
        groups={state.groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        position={state.position}
        setPosition={(pos) => updateState({ position: pos })}
        apiUrl={apiUrl}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        controlEdit={controlEdit}
        refreshState={state.refreshState}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(filter) => updateState({ columnsFilter: filter })}
        viewFilter={state.viewFilter}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        pinnedColumns={state.pinnedColumns}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
        pathname={pathname}
        updateBoard={updateBoard}
        updateRows={state.updateRows}
        selectionRow={state.selectionRow}
        setSelectionRow={(selectionRow) => updateState({ selectionRow })}
        selectedView={state.selectedView}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default AlunaMasterBranding;
