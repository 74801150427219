import React from "react";
import container from "../../../../styles/Global.module.css";
import Threads from "../../../Threads/Threads";
import { countries } from "../../../../json/Countries";
import { Divider, Tooltip } from "@mui/material";
import ViewsCPS from "../../../others/ViewsCPS";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import {
  csmStatusCPSOptions,
  afiliacionStatusCPSOptions,
  offerStatusCPSOptions,
  pixelStatus,
} from "../../../../json/StatusOptions";
import { useLocation } from "react-router-dom";
import ColumnStatus from "../../../../components/global/fields/ColumnStatus.jsx";
import EditableText from "../../../../components/global/fields/EditableText.jsx";
import { getStatus } from "../../../../utils/ApiCall";
import EditableSelectMultiple from "../../../../components/global/fields/EditableSelectMultiple.jsx";
import EditableSelect from "../../../../components/global/fields/EditableSelect.jsx";
import {
  otherOptions,
  publisherOptions,
  programManager,
  encargadaCsm,
} from "../../../../json/CampaignCreator";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AlunaMasterCPS = () => {
  const apiUrl = `${backendUrl}/api/master-cps/items`;
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const tableByParams = searchParams.get("table");

  const [state, updateState] = useCustomState({
    groups: ["Offer ID", "Inactive", "Pixel Integration"],
    offerID: [],
    inactive: [],
    pixelIntegration: [],
    status: tableByParams ?? "Offer ID",
    selectedView: "tabla-principal",
    selectionRow: [],
    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    columnsFilter: {
      revenueRetargeting: false,
      id: false,
    },
    pinnedColumns: {
      left: ["__check__", "id", "offerID", "threads", "offerTitle"],
      right: [],
    },
    modalThreadOpen: false,
    initialViews: true,
    updateRows: [],
  });

  const mapFilterOptionsCode = (options) => {
    const filterOptions = options.map((option) => {
      return option.code;
    });
    return filterOptions;
  };

  const filterItemsByStatus = (items, status) => {
    let itemsFiltered = items.filter((item) => {
      if (item.offerStatus === status) return true;
      else if (
        status === "Offer ID" &&
        item.offerStatus !== "INACTIVE" &&
        item.offerStatus !== "PIXEL INTEGRATION"
      ) {
        return true;
      }
      return false;
    });
    return itemsFiltered;
  };

  const filterItemsAndSetGroups = async (items) => {
    updateState({
      offerID: filterItemsByStatus(await items, "Offer ID"),
      inactive: filterItemsByStatus(items, "INACTIVE"),
      pixelIntegration: filterItemsByStatus(items, "PIXEL INTEGRATION"),
    });
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    let status;
    if (field !== "offerStatus") {
      status = await getStatus(rowID, "master-cps");
    } else {
      status = newValue;
    }

    if (status === "PIXEL INTEGRATION") {
      const updatedRowsPixelI = state.pixelIntegration.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      updateState({ pixelIntegration: updatedRowsPixelI });
    } else if (status === "INACTIVE") {
      const updatedRowsInactives = state.inactive.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      updateState({ inactive: updatedRowsInactives });
    } else {
      const updatedRowsOfferID = state.offerID.map((row) => {
        if (+row.id === +rowID) {
          return { ...row, [`${field}`]: newValue };
        }
        return row;
      });
      updateState({ offerID: updatedRowsOfferID });
    }
  };

  const getGroupRows = (groupName) => {
    return groupName === "Offer ID"
      ? state.offerID
      : groupName === "Inactive"
      ? state.inactive
      : groupName === "Pixel Integration"
      ? state.pixelIntegration
      : [];
  };

  const updateBoard = (newData) => {
    const updatedRows = [...state.updateRows, newData];

    updateState({ updateRows: updatedRows });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "offerID",
      headerName: "Offer ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "offerTitle",
      headerName: "Offer Title",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "offerStatus",
      headerName: "OFFER Status",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"offerStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={offerStatusCPSOptions}
        />
      ),
    },
    {
      field: "publisher",
      headerName: "Publisher",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"publisher"}
          handleEditResponseChange={handleEditResponseChange}
          options={publisherOptions.map((opt) => opt.title)}
          getOptionLabelFunc={(opt) => opt}
          initialValue={params.row.publisher}
          label="Publisher"
        />
      ),
    },
    {
      field: "other",
      headerName: "Other",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"other"}
          handleEditResponseChange={handleEditResponseChange}
          options={otherOptions.map((opt) => opt.title)}
          getOptionLabelFunc={(opt) => opt}
          initialValue={params.row.other}
          label="Other"
        />
      ),
    },
    {
      field: "csmStatus",
      headerName: "CSM Status",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"csmStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={csmStatusCPSOptions}
        />
      ),
    },
    {
      field: "affiliacionStatus",
      headerName: "Affiliacion Status",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"affiliacionStatus"}
          columnPosition={270}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={afiliacionStatusCPSOptions}
        />
      ),
    },
    {
      field: "pixelStatus",
      headerName: "Verificación de Pixel",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",
      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"pixelStatus"}
          columnPosition={122}
          optionsAmount={"low"}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={pixelStatus}
        />
      ),
    },
    {
      field: "advertiserName",
      headerName: "Advertiser Name",
      width: 160,
      headerAlign: "center",
      cellClassName: "cell-content",
      align: "center",

      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip title={params.value}>
            <p className="cell-data">{params.value}</p>
          </Tooltip>{" "}
        </div>
      ),
    },
    {
      field: "typeOfClient",
      headerName: "Tipo de cliente",
      width: 120,
      headerAlign: "center",
      cellClassName: "cell-content",
      align: "center",

      renderCell: (params) => (
        <div>
          <p className="cell-data">{params.value}</p>
        </div>
      ),
    },
    {
      field: "encargadaCsm",
      headerName: "Encargada CSM",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"encargadaCsm"}
          handleEditResponseChange={handleEditResponseChange}
          options={encargadaCsm.map((encargada) => encargada.name)}
          spanText={params.row.encargadaCsm}
          initialValue={params.row.encargadaCsm}
          label="Encargada CSM"
        />
      ),
    },
    {
      field: "programManager",
      headerName: "Program Manager",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"programManager"}
          handleEditResponseChange={handleEditResponseChange}
          options={programManager.map((manager) => manager.name)}
          spanText={params.row.programManager}
          initialValue={params.row.programManager}
          label="Program Manager"
        />
      ),
    },
    {
      field: "affiseLink",
      headerName: "Affise Link",
      width: 200,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <Tooltip title={params.value}>
            <a
              href={params.value}
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none", color: "var(--blueFour)" }}
            >
              {params.value}
            </a>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "BAT_Preview",
      headerName: "BAT/Preview",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div>
          <p className="cell-data">{params.value}</p>
        </div>
      ),
    },
    {
      field: "conversionType",
      headerName: "Conversion Type",
      width: 160,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => (
        <div>
          <Tooltip title={params.value}>
            <p className="cell-data">{params.value}</p>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "country",
      headerName: "Country",
      width: 160,
      headerAlign: "center",
      align: "center",
      type: "singleSelect",
      valueOptions: mapFilterOptionsCode(countries),
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "uppercase",
          }}
        >
          {countries.map((el) => {
            if (params?.value === el.label || params?.value === el.code) {
              return (
                <>
                  <img
                    src={`https://flagcdn.com/w20/${el.code.toLowerCase()}.png`}
                    style={{ marginRight: "8px" }}
                    alt="img"
                  />
                </>
              );
            } else return null;
          })}
          {countries.map((el) => {
            if (
              params?.value?.toLowerCase() === el.label.toLowerCase() ||
              params?.value?.toLowerCase() === el.code.toLowerCase()
            ) {
              return <p>{el.code}</p>;
            } else return null;
          })}
        </div>
      ),
    },

    {
      field: "vertical",
      headerName: "Vertical",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip title={params.value}>
            <p className="cell-data">{params.value}</p>
          </Tooltip>{" "}
        </div>
      ),
    },
    {
      field: "trafficSources",
      headerName: "Traffic Sources Allowed",
      width: 220,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div className="container-cell-data">
          <Tooltip title={params.value}>
            <p className="cell-data">{params.value}</p>
          </Tooltip>{" "}
        </div>
      ),
    },
    {
      field: "revenueRetargeting",
      headerName: "Revenue Retargeting",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "monedaAnunciante",
      headerName: "Moneda Anunciante",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "payout",
      headerName: "Payout",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "previewLink",
      headerName: "Preview Link",
      width: 160,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <div
          style={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <a
            href={params.value}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "var(--blueFour)" }}
          >
            {params.value}
          </a>
        </div>
      ),
    },
    {
      field: "targeting",
      headerName: "Targeting",
      width: 160,

      align: "center",
      headerAlign: "center",
    },
    {
      field: "timeToValidation",
      headerName: "Tiempo de validación",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"timeToValidation"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params?.row?.timeToValidation}
        />
      ),
    },
    {
      field: "revenue",
      headerName: "Revenue",
      width: 100,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "revMaximo",
      headerName: "Revenue Máximo",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "affiseClicks",
      headerName: "Affise clicks",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pendingPayout",
      headerName: "Pending payout",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pendingRevenue",
      headerName: "Pending revenue",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "pendingQty",
      headerName: "Pending QTY",
      width: 160,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "ticketPromedio",
      headerName: "Ticket Promedio",
      width: 160,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"ticketPromedio"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.ticketPromedio}
        />
      ),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <ViewsCPS
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        setTables={(groups) => updateState({ groups })}
        initialViews={state.initialViews}
        setSelectedView={(selectedView) => updateState({ selectedView })}
        selectedView={state.selectedView}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
      />
      <Divider sx={{ marginBottom: "20px" }} />
      <BaseBoard
        columns={columns}
        groups={state.groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        updateBoard={updateBoard}
        updateRows={state.updateRows}
        setSelectionRow={(selectionRow) => updateState({ selectionRow })}
        selectionRow={state.selectionRow}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        viewFilter={state.viewFilter}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        pinnedColumns={state.pinnedColumns}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default AlunaMasterCPS;
