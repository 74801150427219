import React from "react";
import Swal from "sweetalert2";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  Box,
  Button,
  Divider,
  Fade,
  IconButton,
  Modal,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import CloseIcon from "@mui/icons-material/Close";
import { editTagAluna } from "../../../../../../utils/ApiCall.js";
import style from "./ModalEdit.module.css";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1200,
  height: 600,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  display: "flex",
  flexDirection: "column",
  overflowY: "scroll",
};

const ModalEdit = ({
  handleClose,
  modalOpen,
  offerFields,
  setOfferFields,
  showSnackbar,
}) => {
  const handleContentChange = (index, contentType, newContent) => {
    setOfferFields((prevFields) => {
      const newPlace = [...prevFields.place];
      newPlace[index][contentType].content = newContent;

      return {
        ...prevFields,
        place: newPlace,
      };
    });
  };

  const handleEdit = async (data) => {
    try {
      const { id } = data;

      const payload = {
        place: data.place.map((place) => ({
          name: place.name,
          basket: {
            content: place.basket.content,
            type: place.basket.type,
          },
          category: {
            content: place.category.content,
            type: place.category.type,
          },
          home: {
            content: place.home.content,
            type: place.home.type,
          },
          product: {
            content: place.product.content,
            type: place.product.type,
          },
          thankyou: {
            content: place.thankyou.content,
            type: place.thankyou.type,
          },
        })),
        offerTitle: data.offerTitle,
        OfferId: data.OfferId,
      };

      const response = await editTagAluna(id, payload);

      setOfferFields(response.data);
    } catch (error) {
      console.error("Error updating tag:", error);
    }
  };

  const handleSubmitEdit = async (e, data) => {
    e.preventDefault();
    handleEdit(data);
    if (data) {
      showSnackbar("success", "Editado exitosamente", true);
    }
  };

  const handleDelete = (name, idx) => {
    Swal.fire({
      title: `Quiere eliminar el tag del afiliado ${name}?`,
      showDenyButton: true,

      confirmButtonText: "Eliminar",
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if (result.isConfirmed) {
        let newOffers = {};
        setOfferFields((prevFields) => {
          const newPlace = [...prevFields.place];
          let newArray = newPlace.filter((element, index) => index !== idx);

          newOffers = {
            ...prevFields,
            place: newArray,
          };
          return {
            ...prevFields,
            place: newArray,
          };
        });

        handleEdit(newOffers);

        Swal.fire("Saved!", "", "success");
      } else if (result.isDenied) {
        Swal.fire("Changes are not saved", "", "info");
      }
    });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={modalOpen}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Fade in={modalOpen}>
        <Box
          sx={modalStyle}
          component="form"
          onSubmit={(e) => handleSubmitEdit(e, offerFields)}
        >
          <Box className={style.closeContainer}>
            <CloseIcon onClick={handleClose} sx={{ cursor: "pointer" }} />
          </Box>
          <Typography id="transition-modal-title" variant="h6" component="h2">
            <Box>
              <div className={style.titleContainer}>
                <p>Offer Title: {offerFields.offerTitle}</p>
                <p>Offer ID: {offerFields.OfferId}</p>
              </div>

              <Divider />

              <Box sx={{ display: "flex", flexDirection: "column" }}>
                {offerFields.place.map((content, index) => (
                  <div key={index}>
                    <p className={style.affiliateName}>
                      Affiliate Name:
                      <span style={{ fontWeight: "400" }}> {content.name}</span>
                      <Tooltip title="Eliminar Tag" sx={{ marginLeft: "10px" }}>
                        <IconButton
                          onClick={() => handleDelete(content.name, index)}
                        >
                          <DeleteOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </p>
                    <div key={index} className={style.modalContainer}>
                      {Object.entries(content).map(
                        ([contentType, contentDetails]) => (
                          <div
                            key={contentType}
                            className={style.textFieldContainer}
                          >
                            {contentType !== "name" && (
                              <TextField
                                value={contentDetails.content}
                                label={`${
                                  contentType.charAt(0).toUpperCase() +
                                  contentType.slice(1)
                                } Content`}
                                sx={{ width: "100%" }}
                                onChange={(e) =>
                                  handleContentChange(
                                    index,
                                    contentType,
                                    e.target.value
                                  )
                                }
                              />
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                ))}
              </Box>
              <div className={style.buttonsContainer}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: "var(--blueFour)",
                    color: "#fcfaff",
                    textTransform: "none",
                    padding: "4px 8px",
                    minWidth: "80px",
                    height: "35px",
                    fontSize: "16px",
                    "&: hover": {
                      backgroundColor: "var(--blue)",
                    },
                  }}
                >
                  Editar
                </Button>
              </div>
            </Box>
          </Typography>
        </Box>
      </Fade>
    </Modal>
  );
};

export default ModalEdit;
