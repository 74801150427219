import React, { useEffect } from "react";
import container from "../../../../styles/Global.module.css";
import { editBoardField, fetchData } from "../../../../utils/ApiCall.js";
import Threads from "../../../Threads/Threads.jsx";
import { comercialCRMStatus } from "../../../../json/StatusOptions.js";
import ColumnStatus from "../../../../components/global/fields/ColumnStatus.jsx";
import EditableText from "../../../../components/global/fields/EditableText.jsx";
import SingleDuration from "../../../../components/global/fields/SingleDuration.jsx";
import EditableSelectCountry from "../../../../components/global/fields/EditableSelectCountry.jsx";
import {
  agencia,
  anio,
  broker,
  crmTipos,
  meses,
  numeroDeMeses,
  porcentajeDeAvance,
  sortAlphabetically,
  tipoDeCompra,
} from "../../../../json/CampaignCreator.js";
import { countries } from "../../../../json/Countries.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import SingleUserSelect from "../../../../components/global/fields/SingleUserSelect.jsx";
import EditableSelect from "../../../../components/global/fields/EditableSelect.jsx";
import EditableSelectMultiple from "../../../../components/global/fields/EditableSelectMultiple.jsx";
import { Currencies } from "../../../../json/Currencies.js";
import EditableFieldText from "../../../../components/global/fields/EditableFieldText.jsx";
import axios from "axios";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const CRM = () => {
  const apiUrl = `${backendUrl}/api/crm/items`;
  const apiService = `${backendUrl}/api/offer-service`;
  const apiUsers = `${backendUrl}/api/users`;

  const [state, updateState] = useCustomState({
    status: "crm",
    groups: ["crm"],
    users: [],
    categories: [],
    refreshState: false,
    items: [],
    selectionRow: [],
    updateRows: [],
    investByMonth: "",
    columnsFilter: { id: false },
    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    pinnedColumns: {
      left: ["__check__", "threads"],
      right: [],
    },
  });

  useEffect(() => {
    const fetchApi = async () => {
      const resUsers = await fetch(apiUsers);
      const data = await resUsers.json();
      updateState({ users: data });

      const resCategories = await fetchData(`${apiService}/getCategories`);
      updateState({ categories: sortAlphabetically(resCategories, "title") });
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterItemsAndSetGroups = (items) => {
    updateState({ items });
  };

  const controlEdit = async (params) => {
    let ids =
      state.selectionRow.length !== 0 ? state.selectionRow : [params.id];
    switch (params.field) {
      case "investByMonth":
        await editBoardField("/crm", ids, params.field, state.investByMonth);

        updateState({ refreshState: true });
        break;
      default:
        break;
    }
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    const updatedRowsNoTerminados = state.items.map((row) => {
      if (+row.id === +rowID) {
        return { ...row, [`${field}`]: newValue };
      }
      return row;
    });

    updateState({ items: updatedRowsNoTerminados });

    if (field === "investByMonth" || field === "numberMonth") {
      updateTotalInvestment(rowID, updatedRowsNoTerminados);
    }
  };

  const updateTotalInvestment = async (rowID, updatedRows) => {
    const row = updatedRows.find((row) => +row.id === +rowID);
    const investByMonth = parseFloat(row.investByMonth) || 0;
    const numberMonth = parseFloat(row.numberMonth) || 0;
    const totalInvest = investByMonth * numberMonth;

    const reqOptions = {
      url: `${backendUrl}/api/crm/edit/${rowID}`,
      method: "PUT",
      headers: {
        Accept: "*/*",
        Authorization: `Bearer ${localStorage?.getItem("jwt")}`,
      },
      data: { data: { totalInvest: totalInvest } },
    };

    try {
      const response = await axios.request(reqOptions);
      return response.data.data;
    } catch (error) {
      if (error.response && error.response.status === 400) {
        return error.response;
      }
      throw new Error(
        error.response ? error.response.data.error.message : "Error desconocido"
      );
    }
  };

  const getGroupRows = (groupName) => {
    return groupName === "crm" ? state.items : [];
  };

  const updateBoard = (newData) => {
    const updatedRows = [...state.updateRows, newData];

    updateState({ updateRows: updatedRows });
  };

  const formatNumber = (num) => {
    if (!num) return "";
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "commercial",
      headerName: "Comercial",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const filteredUsers = state.users.filter((user) =>
          ["vperez@aluna.co", "alexis@aluna.co"].includes(user.email)
        );

        const mappedUsers = filteredUsers.map((user) => ({
          ...user,
          username:
            user.email === "vperez@aluna.co" ? "Victor Perez" : "Alexis Peña",
        }));

        return (
          <SingleUserSelect
            {...params}
            users={mappedUsers}
            field={"commercial"}
            handleEditResponseChange={handleEditResponseChange}
          />
        );
      },
      valueGetter: (params) => {
        const user = state.users.find(
          (user) => user.username === params.row.commercial?.username
        );

        return user
          ? user.username === "vperez@aluna.co"
            ? "Victor Perez"
            : "Alexis Peña"
          : "Sin asignar";
      },
    },
    {
      field: "agency",
      headerName: "Agencia",
      width: 160,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"agency"}
          handleEditResponseChange={handleEditResponseChange}
          options={agencia}
          spanText={params.row.agency}
          initialValue={params.row.agency}
          label="Agencia"
        />
      ),
    },
    {
      field: "country",
      headerName: "País",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectCountry
          {...params}
          field={"country"}
          handleEditResponseChange={handleEditResponseChange}
          options={countries}
          initialValue={params.row.country}
        />
      ),
    },
    {
      field: "advertiser",
      headerName: "Anunciante",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"advertiser"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.advertiser}
        />
      ),
    },
    {
      field: "offerTitle",
      headerName: "Campaña",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"offerTitle"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.offerTitle}
        />
      ),
    },
    {
      field: "type",
      headerName: "Tipo",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"type"}
          handleEditResponseChange={handleEditResponseChange}
          options={crmTipos.map((opt) => opt)}
          getOptionLabelFunc={(opt) => opt}
          initialValue={params.row.type}
          label="Tipo"
        />
      ),
    },
    {
      field: "conversionType",
      headerName: "Tipo de compra",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"conversionType"}
          handleEditResponseChange={handleEditResponseChange}
          options={tipoDeCompra.map((opt) => opt.name)}
          getOptionLabelFunc={(opt) => opt}
          initialValue={params.row.conversionType}
          label="Tipo de compra"
        />
      ),
    },
    {
      field: "vertical",
      headerName: "Vertical",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectMultiple
          {...params}
          field={"vertical"}
          handleEditResponseChange={handleEditResponseChange}
          options={state.categories.map((item) => item.title)}
          getOptionLabelFunc={(vertical) => vertical}
          initialValue={params.row.vertical}
          label="Vertical"
        />
      ),
    },
    {
      field: "broker",
      headerName: "Broker",
      width: 160,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"broker"}
          handleEditResponseChange={handleEditResponseChange}
          options={broker}
          spanText={params.row.broker}
          initialValue={params.row.broker}
          label="Broker"
        />
      ),
    },
    {
      field: "firstContactDate",
      headerName: "Fecha del primer contacto",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleDuration
          {...params}
          selectionRow={state.selectionRow}
          fieldKey="firstContactDate"
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"status"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={comercialCRMStatus}
        />
      ),
    },
    {
      field: "reminder",
      headerName: "Recordatorio",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleDuration
          {...params}
          selectionRow={state.selectionRow}
          isReminder={true}
          fieldKey="reminder"
        />
      ),
    },
    {
      field: "year",
      headerName: "Año",
      width: 160,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"year"}
          handleEditResponseChange={handleEditResponseChange}
          options={anio}
          spanText={params.row.year}
          initialValue={params.row.year}
          label="Año"
        />
      ),
    },
    {
      field: "month",
      headerName: "Mes estimado de inicio",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"month"}
          handleEditResponseChange={handleEditResponseChange}
          options={meses.map((opt) => opt)}
          spanText={params.row.month}
          initialValue={params.row.month}
          label="Mes estimado de inicio"
        />
      ),
    },
    {
      field: "numberMonth",
      headerName: "Numero de meses activos estimados",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"numberMonth"}
          handleEditResponseChange={handleEditResponseChange}
          options={numeroDeMeses.map((opt) => opt)}
          spanText={params.row.numberMonth}
          initialValue={params.row.numberMonth}
          label="Numero de meses activos estimados"
        />
      ),
    },
    {
      field: "investByMonth",
      headerName: "Estimado de inversion por mes",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => formatNumber(params?.value?.toString()),
      renderEditCell: (params) => (
        <EditableFieldText
          {...params}
          onStateChange={(value) => {
            updateState({ investByMonth: value });
          }}
        />
      ),
      editable: true,
    },
    {
      field: "currency",
      headerName: "Moneda",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"currency"}
          handleEditResponseChange={handleEditResponseChange}
          options={Currencies.filter((currency) =>
            ["USD", "MXN", "COP"].includes(currency)
          )}
          spanText={<p className="cell-data">{params.value}</p>}
          initialValue={params.row.currency}
          label="Moneda"
          offerID={params.row.offerID}
        />
      ),
    },
    {
      field: "totalInvest",
      headerName: "Inversion Total Estimada",
      width: 180,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const investByMonth = parseFloat(params.row.investByMonth) || 0;
        const numberMonth = parseFloat(params.row.numberMonth) || 0;

        const totalInvest = investByMonth * numberMonth;

        return formatNumber(totalInvest.toString());
      },
    },
    {
      field: "percentageDone",
      headerName: "Porcentaje de Avance",
      width: 170,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"percentageDone"}
          handleEditResponseChange={handleEditResponseChange}
          options={porcentajeDeAvance.map((opt) => opt)}
          spanText={
            params.row.percentageDone != null
              ? `${params.row.percentageDone}%`
              : "-"
          }
          initialValue={params.row.percentageDone ?? "0"}
          label="Porcentaje de Avance"
        />
      ),
    },
    {
      field: "contact",
      headerName: "Contacto",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"contact"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.contact}
        />
      ),
    },
    {
      field: "contactEmail",
      headerName: "Email Contacto",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"contactEmail"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.contactEmail}
        />
      ),
    },
    {
      field: "contactCharged",
      headerName: "Cargo Contacto",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"contactCharged"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.contactCharged}
        />
      ),
    },
    {
      field: "comment",
      headerName: "Comentario",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"comment"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.comment}
        />
      ),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <BaseBoard
        columns={columns}
        groups={state.groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        updateBoard={updateBoard}
        updateRows={state.updateRows}
        setSelectionRow={(selectionRow) => updateState({ selectionRow })}
        selectionRow={state.selectionRow}
        controlEdit={controlEdit}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        viewFilter={state.viewFilter}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        pinnedColumns={state.pinnedColumns}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
        checkboxSelection={true}
        refreshState={state.refreshState}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default CRM;
