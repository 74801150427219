import React, { useContext, useEffect, useRef } from "react";
import {
  Button,
  TextField,
  Autocomplete,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  FormControlLabel,
  Checkbox,
  Tooltip,
  InputAdornment,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { UserContext } from "../../../../UserContext";
import { fetchData, getProgressStatusQueue } from "../../../../utils/ApiCall";
import container from "../../../../styles/Global.module.css";
import { useCustomState } from "../../../../utils/useCustomState";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const CreacionDeConversiones = () => {
  const apiUrl = `${backendUrl}/api/offer-service/`;
  const { key, setKey } = useContext(UserContext);
  setKey(localStorage?.getItem("jwt"));

  const [state, updateState] = useCustomState({
    date: null,
    offer: null,
    affiliate: null,
    status: "",
    quantity: "",
    revenue: null,
    payout: null,
    searchText: "",
    searchTextAffiliates: "",
    offerID: "",
    affiliateID: "",
    searchResult: [],
    affiliates: [],
    loading: false,
    isSearching: false,
    isSearchingAffiliates: false,
    isListOpen: false,
    isListOpenAffiliates: false,
    isTextFieldClicked: false,
    isTextFieldClickedAffiliates: false,
    searchByOfferID: false,
    searchByAffiliateID: false,
    jobId: null,
    requestCompleted: false,
    editPayout: false,
    editRevenue: false,
  });

  const resetForm = React.useCallback(() => {
    updateState({
      date: null,
      offer: null,
      affiliate: null,
      status: "",
      quantity: "",
      revenue: "",
      payout: "",
      searchText: "",
      searchTextAffiliates: "",
      editPayout: false,
      editRevenue: false,
    });
  }, [updateState]);

  const statuses = ["PENDING", "CONFIRMED", "DECLINED"];

  useEffect(() => {
    if (state.searchText && !state.offerID) {
      handleSearchOffers();
    } else {
      updateState({ searchResult: [], isSearching: false, isListOpen: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.searchText,
    state.searchByOfferID,
    state.isTextFieldClicked,
    state.isSearching,
  ]);

  useEffect(() => {
    if (state.searchTextAffiliates && !state.affiliateID) {
      handleSearchAffiliates();
    } else {
      updateState({
        affiliates: [],
        isSearchingAffiliates: false,
        isListOpenAffiliates: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.searchTextAffiliates,
    state.searchByAffiliateID,
    state.isTextFieldClickedAffiliates,
    state.isSearchingAffiliates,
  ]);

  useEffect(() => {
    const getProgress = async () => {
      try {
        const progressQueue = await getProgressStatusQueue(
          `getStatusAddRevPay?id=${state.jobId}`
        );
        if (progressQueue.state === "completed" && state.jobId) {
          updateState({
            loading: false,
            requestCompleted: true,
            jobId: null,
          });
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    if (state.loading && state.jobId) {
      const interval = setInterval(getProgress, 500);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.jobId]);

  useEffect(() => {
    if (state.requestCompleted) {
      resetForm();
      Swal.fire(
        "Success",
        "Data submitted successfully. Changes may take a few seconds to be reflected in Affise.",
        "success"
      );
      updateState({ requestCompleted: false });
    }
  }, [resetForm, state.requestCompleted, updateState]);

  const handleSearchOffers = async () => {
    if (state.searchByOfferID) {
      searchOffers(state.searchText, "offerID");
    } else {
      searchOffers(state.searchText, "offerTitle");
    }
    updateState({ isSearching: true, isListOpen: true });
  };

  const handleSearchAffiliates = async () => {
    if (state.searchByAffiliateID) {
      searchAffiliates(state.searchTextAffiliates, "affiseID");
    } else {
      searchAffiliates(state.searchTextAffiliates, "title");
    }
    updateState({ isSearchingAffiliates: true, isListOpenAffiliates: true });
  };

  const searchOffers = async (query, searchParam) => {
    const url = `${backendUrl}/api/offer-service/autocompleteOffersMasters?${searchParam}=${query}`;
    const resOffers = await fetchData(url);

    updateState({ searchResult: resOffers });
  };

  const searchAffiliates = async (query, searchParam) => {
    const url = `${apiUrl}autocompleteAffiliates?${searchParam}=${query}`;
    const resOffers = await fetchData(url);

    updateState({ affiliates: resOffers });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateState({ [name]: value });
  };

  const handleAutocompleteChange = (name, value) => {
    updateState({ [name]: value });
  };

  const handleDateChange = (newDate) => {
    updateState({ date: newDate });
  };

  const handleLiClick = (result) => {
    updateState({
      offer: result,
      searchText: result.offerTitle,
      isTextFieldClicked: false,
      offerID: result.offerID,
      isListOpen: false,
    });
  };

  const handleLiClickAffiliates = (result) => {
    updateState({
      affiliate: result,
      searchTextAffiliates: result.title,
      isTextFieldClickedAffiliates: false,
      affiliateID: result.affiseID,
      isListOpenAffiliates: false,
    });
  };

  const handleSearchTextChange = (e) => {
    updateState({
      searchText: e.target.value,
      searchByOfferID: !isNaN(e.target.value),
      isTextFieldClicked: false,
      offerID: "",
    });
  };

  const handleSearchTextChangeAffiliates = (e) => {
    updateState({
      searchTextAffiliates: e.target.value,
      searchByAffiliateID: !isNaN(e.target.value),
      isTextFieldClickedAffiliates: false,
      affiliateID: "",
    });
  };

  const handleEditPayout = () => {
    if (state.editPayout) {
      updateState({ payout: "" });
    }
    updateState({ editPayout: !state.editPayout });
  };
  const handleEditRevenue = () => {
    if (state.editRevenue) {
      updateState({ revenue: "" });
    }
    updateState({ editRevenue: !state.editRevenue });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    updateState({ loading: true });

    const data = {
      date: state.date ? dayjs(state.date).format("YYYY-MM-DD") : null,
      offer: state.offer ? state.offer.offerID : null,
      affiliate: state.affiliate ? state.affiliate.affiseID : null,
      status: state.status.toLowerCase(),
      quantity: state.quantity,
      revenue: state.revenue,
      payout: state.payout,
    };

    try {
      const response = await fetch(
        `${backendUrl}/api/offer-service/createConversions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${key}`,
          },
          body: JSON.stringify(data),
        }
      );
      if (response.ok) {
        const responseJSON = await response.json();

        if (!responseJSON.jobId) {
          Swal.fire(
            "Success",
            "Data submitted successfully. Changes may take a few seconds to be reflected in Affise.",
            "success"
          );
          updateState({ loading: false });
        } else {
          updateState({ jobId: responseJSON.jobId });
        }
        resetForm();
      } else {
        Swal.fire("Error", "Failed to submit data", "error");
        updateState({ loading: false });
      }
    } catch (error) {
      updateState({ loading: false });
      Swal.fire("Error", "Failed to submit data", "error");
    }
  };

  const offerRef = useRef(null);
  const affiliateRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        offerRef.current &&
        !offerRef.current.contains(event.target) &&
        state.isListOpen
      ) {
        updateState({ isListOpen: false });
      }
      if (
        affiliateRef.current &&
        !affiliateRef.current.contains(event.target) &&
        state.isListOpenAffiliates
      ) {
        updateState({ isListOpenAffiliates: false });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [state.isListOpen, state.isListOpenAffiliates, updateState]);

  return (
    <Box className={container.globalContainer}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: "600px",
            marginTop: "20px",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="*Fecha"
                value={state.date}
                onChange={handleDateChange}
                renderInput={(params) => <TextField {...params} required />}
                sx={{ width: "100%", backgroundColor: "white" }}
              />
            </LocalizationProvider>

            <div style={{ display: "flex" }}>
              <div>
                <TextField
                  type="text"
                  label="Offer ID / Offer Title"
                  value={state.searchText}
                  // disabled={isLoadingRows}
                  onChange={handleSearchTextChange}
                  onClick={() => updateState({ isTextFieldClicked: true })}
                  sx={{
                    width: "350px",
                    backgroundColor: "white",
                    position: "relative",
                  }}
                />

                {state.isSearching &&
                  state.searchResult.length === 0 &&
                  state.searchText !== "" && (
                    <List
                      component="ul"
                      className="autocomplete-ul"
                      sx={{
                        position: "absolute",
                        width: "350px",
                        zIndex: "1000",
                      }}
                    >
                      <ListItem className="autocomplete-li" disabled={true}>
                        <ListItemText primary="Offer no encontrada" />
                      </ListItem>
                    </List>
                  )}

                {state.isSearching &&
                  state.isListOpen &&
                  state.searchResult.length > 0 && (
                    <List
                      component="ul"
                      className="autocomplete-ul"
                      sx={{
                        width: "350px",
                        zIndex: "1000",
                        position: "absolute",
                      }}
                      ref={offerRef}
                    >
                      {state.isSearching &&
                        state.isListOpen &&
                        state.searchResult
                          .filter(
                            (result) =>
                              result?.offerTitle
                                ?.toLowerCase()
                                ?.includes(state.searchText?.toLowerCase()) ||
                              result?.offerID?.toString() === state.searchText
                          )
                          .map((result) => (
                            <ListItem
                              key={result.offerID}
                              onClick={() => handleLiClick(result)}
                              className="autocomplete-li"
                              sx={{
                                color: "black",
                                fontWeight: "700",
                                margin: "5px 0px",
                                "&:hover": {
                                  backgroundColor: "var(--blueLowTwo)",
                                },
                              }}
                            >
                              <ListItemText primary={result.offerTitle} />
                            </ListItem>
                          ))}
                    </List>
                  )}
              </div>
            </div>
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <div style={{ marginRight: "15px" }}>
              <TextField
                type="text"
                label="Affiliate ID / Affiliate Title"
                value={state.searchTextAffiliates}
                // disabled={isLoadingRowsAffiliates}
                onChange={handleSearchTextChangeAffiliates}
                onClick={() =>
                  updateState({ isTextFieldClickedAffiliates: true })
                }
                sx={{
                  width: "350px",
                  backgroundColor: "white",
                  position: "relative",
                }}
              />

              {state.isSearchingAffiliates &&
                state.affiliates.length === 0 &&
                state.searchTextAffiliates !== "" && (
                  <List
                    component="ul"
                    className="autocomplete-ul"
                    sx={{
                      position: "absolute",
                      width: "350px",
                      zIndex: "1000",
                    }}
                  >
                    <ListItem className="autocomplete-li" disabled={true}>
                      <ListItemText primary="Offer no encontrada" />
                    </ListItem>
                  </List>
                )}

              {state.isSearchingAffiliates &&
                state.isListOpenAffiliates &&
                state.affiliates.length > 0 && (
                  <List
                    component="ul"
                    className="autocomplete-ul"
                    sx={{
                      width: "350px",
                      zIndex: "1000",
                      position: "absolute",
                    }}
                    ref={affiliateRef}
                  >
                    {state.isSearchingAffiliates &&
                      state.isListOpenAffiliates &&
                      state.affiliates
                        .filter(
                          (result) =>
                            result?.title
                              ?.toLowerCase()
                              ?.includes(
                                state.searchTextAffiliates?.toLowerCase()
                              ) ||
                            result?.affiseID?.toString() ===
                              state.searchTextAffiliates
                        )
                        .map((result) => (
                          <ListItem
                            key={result.affiseID}
                            onClick={() => handleLiClickAffiliates(result)}
                            className="autocomplete-li"
                            // disabled={isLoadingRowsAffiliates}
                            sx={{
                              color: "black",
                              fontWeight: "700",
                              margin: "5px 0px",
                              "&:hover": {
                                backgroundColor: "var(--blueLowTwo)",
                              },
                            }}
                          >
                            <ListItemText primary={result.title} />
                          </ListItem>
                        ))}
                  </List>
                )}
            </div>
            <Autocomplete
              options={statuses}
              onChange={(event, newValue) =>
                handleAutocompleteChange("status", newValue)
              }
              value={state.status}
              renderInput={(params) => (
                <TextField {...params} label="Status" required />
              )}
              sx={{ width: "100%", backgroundColor: "white" }}
            />
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              label="Cantidad"
              type="number"
              name="quantity"
              value={state.quantity}
              onChange={handleChange}
              required
              sx={{ width: "100%", backgroundColor: "white" }}
            />
            <div style={{ display: "flex", width: "100%" }}>
              <TextField
                label="Revenue"
                type="number"
                name="revenue"
                placeholder="Revenue"
                value={state.revenue}
                disabled={!state.editRevenue}
                onChange={handleChange}
                required
                sx={{ width: "100%", backgroundColor: "white" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title="Agregar payout">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={handleEditRevenue}
                              checked={state.editRevenue}
                            />
                          }
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div style={{ display: "flex", width: "100%" }}>
              <TextField
                label="Payout"
                type="number"
                name="payout"
                placeholder="Payout"
                value={state.payout}
                disabled={!state.editPayout}
                onChange={handleChange}
                required
                sx={{ width: "100%", backgroundColor: "white" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Tooltip title="Agregar payout">
                        <FormControlLabel
                          control={
                            <Checkbox
                              onClick={handleEditPayout}
                              checked={state.editPayout}
                            />
                          }
                        />
                      </Tooltip>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={state.loading}
            >
              {state.loading ? <CircularProgress size={24} /> : "Submit"}
            </Button>
          </Box>
        </Box>
      </div>
    </Box>
  );
};

export default CreacionDeConversiones;
