import React, { useEffect } from "react";
import container from "../../../../styles/Global.module.css";
import Threads from "../../../Threads/Threads.jsx";
import { prospectingComercialStatus } from "../../../../json/StatusOptions.js";
import ColumnStatus from "../../../../components/global/fields/ColumnStatus.jsx";
import EditableText from "../../../../components/global/fields/EditableText.jsx";
import SingleDuration from "../../../../components/global/fields/SingleDuration.jsx";
import EditableSelectCountry from "../../../../components/global/fields/EditableSelectCountry.jsx";
import { agencia } from "../../../../json/CampaignCreator.js";
import { countries } from "../../../../json/Countries.js";
import BaseBoard from "../../../global/BoardTemplate/BaseBoard.jsx";
import { GridLogicOperator } from "@mui/x-data-grid-pro";
import SingleUserSelect from "../../../../components/global/fields/SingleUserSelect.jsx";
import EditableSelect from "../../../../components/global/fields/EditableSelect.jsx";
import { useCustomState } from "../../../../utils/useCustomState.js";

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const Prospecting = () => {
  const apiUrl = `${backendUrl}/api/prospecting/items`;
  const apiUsers = `${backendUrl}/api/users`;

  const [state, updateState] = useCustomState({
    groups: ["Prospecting"],
    status: "Prospecting",
    users: [],
    items: [],
    selectionRow: [],
    updateRows: [],
    columnsFilter: { id: false },
    viewFilter: {
      items: [],
      quickFilterLogicOperator: GridLogicOperator.Or,
    },
    pinnedColumns: {
      left: ["__check__", "threads"],
      right: [],
    },
  });
  console.log(state, "STATEEE");

  useEffect(() => {
    const fetchApi = async () => {
      const resUsers = await fetch(apiUsers);
      const data = await resUsers.json();

      updateState({ users: data });
    };

    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterItemsAndSetGroups = (items) => {
    updateState({ items });
  };

  const handleEditResponseChange = async (newValue, rowID, field) => {
    const updatedRowsNoTerminados = state.items.map((row) => {
      if (+row.id === +rowID) {
        return { ...row, [`${field}`]: newValue };
      }
      return row;
    });
    updateState({ items: updatedRowsNoTerminados });
  };

  const getGroupRows = (groupName) => {
    return groupName === "Prospecting" ? state.items : [];
  };

  const updateBoard = (newData) => {
    const updatedRows = [...state.updateRows, newData];

    updateState({ updateRows: updatedRows });
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "threads",
      headerName: "Thread",
      width: 70,
      headerAlign: "center",
      align: "center",

      renderCell: (params) => {
        return <Threads {...params} row={params.row} />;
      },
    },
    {
      field: "commercial",
      headerName: "Comercial",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleUserSelect
          {...params}
          users={state.users.filter((user) =>
            ["Victor Perez", "Alexis Peña"].includes(user.username)
          )}
          field={"commercial"}
          handleEditResponseChange={handleEditResponseChange}
        />
      ),
    },
    {
      field: "agency",
      headerName: "Agencia",
      width: 160,
      align: "center",

      headerAlign: "center",
      renderCell: (params) => (
        <EditableSelect
          {...params}
          field={"agency"}
          handleEditResponseChange={handleEditResponseChange}
          options={agencia}
          spanText={params.row.agency}
          initialValue={params.row.agency}
          label="Agencia"
        />
      ),
    },
    {
      field: "country",
      headerName: "País",
      width: 200,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableSelectCountry
          {...params}
          field={"country"}
          handleEditResponseChange={handleEditResponseChange}
          options={countries}
          initialValue={params.row.country}
        />
      ),
    },
    {
      field: "advertiser",
      headerName: "Advertiser",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"advertiser"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.advertiser}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      width: 160,
      cellClassName: "no-padding",
      headerAlign: "center",

      renderCell: (params) => (
        <ColumnStatus
          {...params}
          selectionRow={state.selectionRow}
          field={"status"}
          columnPosition={280}
          handleEditResponseChange={handleEditResponseChange}
          statusOption={prospectingComercialStatus}
        />
      ),
    },
    {
      field: "reminder",
      headerName: "Recordatorio",
      width: 160,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <SingleDuration
          {...params}
          selectionRow={state.selectionRow}
          fieldKey="reminder"
        />
      ),
    },
    {
      field: "contact",
      headerName: "Contacto",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"contact"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.contact}
        />
      ),
    },
    {
      field: "contactCharged",
      headerName: "Cargo contacto",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"contactCharged"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.contactCharged}
        />
      ),
    },
    {
      field: "comment",
      headerName: "Comentario",
      width: 250,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <EditableText
          {...params}
          field={"comment"}
          handleEditResponseChange={handleEditResponseChange}
          initialValue={params.row.comment}
        />
      ),
    },
  ];

  return (
    <div className={container.globalContainer}>
      <BaseBoard
        columns={columns}
        groups={state.groups}
        getGroupRows={getGroupRows}
        setGroupItems={filterItemsAndSetGroups}
        apiUrl={apiUrl}
        status={state.status}
        setStatus={(status) => updateState({ status })}
        updateBoard={updateBoard}
        updateRows={state.updateRows}
        setSelectionRow={(selectionRow) => updateState({ selectionRow })}
        selectionRow={state.selectionRow}
        setViewFilter={(viewFilter) => updateState({ viewFilter })}
        viewFilter={state.viewFilter}
        columnsFilter={state.columnsFilter}
        setColumnsFilter={(columnsFilter) => updateState({ columnsFilter })}
        pinnedColumns={state.pinnedColumns}
        setPinnedColumns={(pinnedColumns) => updateState({ pinnedColumns })}
        checkboxSelection={true}
        height={"80vh"}
        maxHeight={"80vh"}
      />
    </div>
  );
};

export default Prospecting;
