import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { COLORS } from "../../json/StatusOptions";
import HistoryIcon from "@mui/icons-material/History";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import fotoPerfil from "../../assets/icons/perfilionvo.png";
import { CustomToolTip } from "../../components/global/CustomToolTip";
import { useLocation } from "react-router-dom";
import { fetchData } from "../../utils/ApiCall";
import TextFieldsIcon from "@mui/icons-material/TextFields";
import SegmentIcon from "@mui/icons-material/Segment";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import NumbersIcon from "@mui/icons-material/Numbers";
import TimelapseIcon from "@mui/icons-material/Timelapse";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const formatDate = (inputDate) => {
  const date = new Date(inputDate);
  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  const formattedDate = new Intl.DateTimeFormat("es-ES", options).format(date);
  const [datePart, timePart] = formattedDate.split(", ");
  const [hourPart, minutePart] = timePart.split(":");

  const formattedDateString = `${datePart} at ${hourPart}:${minutePart} `;

  return formattedDateString;
};

const formatDateDisplay = (inputDate) => {
  const date = new Date(inputDate);
  const currentDate = new Date();
  const timeDifference = currentDate - date;
  const secondsInADay = 24 * 60 * 60 * 1000;
  const secondsInAMonth = 30 * 24 * 60 * 60 * 1000;
  const secondsInAYear = 365 * 24 * 60 * 60 * 1000;

  if (timeDifference < secondsInADay) {
    return "Today";
  } else if (timeDifference < 2 * secondsInADay) {
    return "1D";
  } else if (timeDifference < secondsInAMonth) {
    const daysAgo = Math.floor(timeDifference / secondsInADay);
    return `${daysAgo}D`;
  } else if (timeDifference < secondsInAYear) {
    const monthsAgo = Math.floor(timeDifference / secondsInAMonth);
    return `${monthsAgo}M`;
  } else {
    const yearsAgo = Math.floor(timeDifference / secondsInAYear);
    return `${yearsAgo}Y`;
  }
};

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ActivityLog = ({ row }) => {
  const location = useLocation();
  const pathname = location.pathname.slice(1);
  const offerID = row.id;

  const apiActivity = `${backendUrl}/api/activity/${offerID}?board=${pathname}`;
  const [activity, setActivity] = useState([]);

  useEffect(() => {
    const fetchApi = async () => {
      const resActivity = await fetchData(apiActivity);
      setActivity(resActivity);
    };
    fetchApi();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(activity, "ACTIVITY");

  const columns = [
    { field: "id", headerName: "ID", width: 90, hide: true },
    {
      field: "createdAt",
      headerName: "Date",
      width: 80,
      resizable: false,
      renderCell: (params) => (
        <div style={{ cursor: "default" }}>
          <CustomToolTip title={formatDate(params.row.createdAt)}>
            <p style={{ display: "flex", alignItems: "center" }}>
              <HistoryIcon fontSize="12px" sx={{ marginRight: "2px" }} />
              {formatDateDisplay(params.row.createdAt)}
            </p>
          </CustomToolTip>
        </div>
      ),
    },
    {
      field: "user",
      headerName: "User",
      width: 180,
      resizable: false,
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", alignItems: "center", cursor: "default" }}
          >
            <CustomToolTip title={params.row.user?.username ?? "kala"}>
              <img
                src={
                  params?.row?.user?.avatar?.url
                    ? `${backendUrl}${params?.row?.user?.avatar?.url}`
                    : fotoPerfil
                }
                style={{ width: "36px", height: "36px", borderRadius: "40px" }}
                alt="Perfil"
              />
            </CustomToolTip>
            <p style={{ marginLeft: "6px", fontSize: "14px" }}>{row.offerID}</p>
          </div>
        );
      },
    },
    {
      field: "fieldName",
      headerName: "Field",
      width: 167,
      resizable: false,
      renderCell: (params) => (
        <div
          style={{ display: "flex", alignItems: "center", cursor: "default" }}
        >
          {params.row.fieldName === "offerTitle" ||
          params.row.fieldName === "targeting" ||
          params.row.fieldName === "ticketPromedio" ||
          params.row.fieldName === "name" ||
          params.row.fieldName === "comments" ||
          params.row.fieldName === "socialReason" ||
          params.row.fieldName === "affiliateName" ||
          params.row.fieldName === "additionalComments" ||
          params.row.fieldName === "actions" ||
          params.row.fieldName === "wishList" ||
          params.row.fieldName === "runningCampaigns" ? (
            <TextFieldsIcon style={{ marginRight: "2px" }} />
          ) : params.row.fieldName === "direct" ||
            params.row.fieldName === "typeOfClient" ||
            params.row.fieldName === "encargadaCsm" ||
            params.row.fieldName === "timeframe" ||
            params.row.fieldName === "currency" ||
            params.row.fieldName === "conversionType" ||
            params.row.fieldName === "country" ||
            params.row.fieldName === "vertical" ||
            params.row.fieldName === "trafficSources" ||
            params.row.fieldName === "monedaAnunciante" ||
            params.row.fieldName === "responsibles" ? (
            <ViewStreamIcon style={{ marginRight: "2px" }} />
          ) : params.row.fieldName === "estimadoCapMensual" ||
            params.row.fieldName === "payoutAceptado" ||
            params.row.fieldName === "KPIClicks" ||
            params.row.fieldName === "CPCMAnunciante" ||
            params.row.fieldName === "CPCClient" ||
            params.row.fieldName === "CPCTrafficSources" ||
            params.row.fieldName === "cap" ||
            params.row.fieldName === "totalBill" ||
            params.row.fieldName === "revenueMAnunciante" ||
            params.row.fieldName === "payAffiliate" ? (
            <NumbersIcon style={{ marginRight: "2px" }} />
          ) : params.row.fieldName === "dateStart" ||
            params.row.fieldName === "dateTo" ||
            params.row.fieldName === "date" ? (
            <TimelapseIcon style={{ marginRight: "2px" }} />
          ) : params.row.fieldName === "reporteInvalidacion" ? (
            <AttachFileIcon style={{ marginRight: "2px" }} />
          ) : (
            <SegmentIcon style={{ marginRight: "2px" }} />
          )}
          {params.row.fieldName === "offerStatus" ? (
            <p>OFFER Status</p>
          ) : params.row.fieldName === "csmStatus" ? (
            <p>CSM Status</p>
          ) : params.row.fieldName === "affiliacionStatus" ? (
            <p>Afiliación Status</p>
          ) : params.row.fieldName === "pixelStatus" ? (
            <p>Verificación de Pixel</p>
          ) : params.row.fieldName === "BAT_Preview" ? (
            <p>BAT/Preview</p>
          ) : params.row.fieldName === "status" ? (
            <p>Estado</p>
          ) : params.row.fieldName === "type" ? (
            <p>Type</p>
          ) : params.row.fieldName === "offerTitle" ? (
            <p>Offer Title</p>
          ) : params.row.fieldName === "direct" ? (
            <p>Direct</p>
          ) : params.row.fieldName === "typeOfClient" ? (
            <p>Tipo de cliente</p>
          ) : params.row.fieldName === "encargadaCsm" ? (
            <p>Encargada CSM</p>
          ) : params.row.fieldName === "timeframe" ? (
            <p>Timeframe</p>
          ) : params.row.fieldName === "targeting" ? (
            <p>Targeting</p>
          ) : params.row.fieldName === "estimadoCapMensual" ? (
            <p>Estimado Cap Mensual</p>
          ) : params.row.fieldName === "payoutAceptado" ? (
            <p>Payout Aceptado</p>
          ) : params.row.fieldName === "ticketPromedio" ? (
            <p>Ticket Promedio</p>
          ) : params.row.fieldName === "KPIClicks" ? (
            <p>Cap Estimado</p>
          ) : params.row.fieldName === "CPCMAnunciante" ? (
            <p>CPC Moneda Anunciante</p>
          ) : params.row.fieldName === "CPCClient" ? (
            <p>CPC Cliente USD</p>
          ) : params.row.fieldName === "CPCTrafficSources" ? (
            <p>CPC Fuente de Tráfico</p>
          ) : params.row.fieldName === "dateStart" ? (
            <p>Date From</p>
          ) : params.row.fieldName === "dateTo" ? (
            <p>Date To</p>
          ) : params.row.fieldName === "cap" ? (
            <p>CAP</p>
          ) : params.row.fieldName === "name" ? (
            <p>Elemento</p>
          ) : params.row.fieldName === "comments" ? (
            <p>Comentario</p>
          ) : params.row.fieldName === "totalBill" ? (
            <p>Total a Facturar</p>
          ) : params.row.fieldName === "currency" ? (
            <p>Currency</p>
          ) : params.row.fieldName === "socialReason" ? (
            <p>Razón Social</p>
          ) : params.row.fieldName === "date" ? (
            <p>Fecha de solicitud</p>
          ) : params.row.fieldName === "conversionType" ? (
            <p>Tipo de Compra</p>
          ) : params.row.fieldName === "affiliateName" ? (
            <p>Afiliado que solicita</p>
          ) : params.row.fieldName === "country" ? (
            <p>País</p>
          ) : params.row.fieldName === "vertical" ? (
            <p>Vertical</p>
          ) : params.row.fieldName === "trafficSources" ? (
            <p>Traffic Source</p>
          ) : params.row.fieldName === "additionalComments" ? (
            <p>Comentarios Adicionales</p>
          ) : params.row.fieldName === "actions" ? (
            <p>Explicación explayada de acciones</p>
          ) : params.row.fieldName === "wishList" ? (
            <p>Wishlist de campañas</p>
          ) : params.row.fieldName === "runningCampaigns" ? (
            <p>Campañas que corre</p>
          ) : params.row.fieldName === "revenueMAnunciante" ? (
            <p>Revenue Moneda Anunciante</p>
          ) : params.row.fieldName === "monedaAnunciante" ? (
            <p>Moneda Anunciante</p>
          ) : params.row.fieldName === "responsibles" ? (
            <p>Responsables</p>
          ) : params.row.fieldName === "payAffiliate" ? (
            <p>Pay Afiliado USD</p>
          ) : params.row.fieldName === "reporteInvalidacion" ? (
            <p>Reporte de Invalidación</p>
          ) : null}
        </div>
      ),
    },
    {
      field: "previousValue",
      headerName: "",
      width: 180,
      align: "right",
      headerAlign: "right",
      resizable: false,
      renderCell: (params) => {
        const selectValueBackgroundColor =
          COLORS[params.row.previousValue] || "transparent";

        return (
          <div
            style={{
              backgroundColor: selectValueBackgroundColor,
              padding: "8px",
              borderRadius: "4px",
              color:
                selectValueBackgroundColor !== "transparent"
                  ? "white"
                  : "black",
              width:
                selectValueBackgroundColor !== "transparent" ? "130px" : "auto",
              overflow: "hidden",
              textAlign: "center",
              textOverflow: "ellipsis",
              cursor: "default",
            }}
          >
            {params.row.previousValue === "month"
              ? "Monthly"
              : params.row.previousValue === "day"
              ? "Daily"
              : params.row.previousValue === "all"
              ? "All"
              : params.row.previousValue}
          </div>
        );
      },
    },
    {
      field: "separator",
      headerName: "",
      maxWidth: 2,
      filterable: false,
      disableColumnMenu: true,
      sortable: false,
      resizable: false,
      align: "center",
      renderCell: () => (
        <div style={{ textAlign: "center" }}>
          <ArrowForwardIosIcon fontSize="12px" />
        </div>
      ),
    },
    {
      field: "actualValue",
      headerName: "",
      width: 180,
      resizable: false,
      renderCell: (params) => {
        const selectValueBackgroundColor =
          COLORS[params.row.actualValue] || "transparent";

        return (
          <div
            style={{
              backgroundColor: selectValueBackgroundColor,
              padding: "8px",
              borderRadius: "4px",
              color:
                selectValueBackgroundColor !== "transparent"
                  ? "white"
                  : "black",
              width:
                selectValueBackgroundColor !== "transparent" ? "130px" : "auto",
              overflow: "hidden",
              textAlign: "center",
              textOverflow: "ellipsis",
              cursor: "default",
            }}
          >
            {params.row.actualValue === "month"
              ? "Monthly"
              : params.row.actualValue === "day"
              ? "Daily"
              : params.row.actualValue === "all"
              ? "All"
              : params.row.actionType === "posted"
              ? `Posted ${params.row.actualValue}`
              : params.row.actionType === "deleted"
              ? `Deleted ${params.row.actualValue}`
              : params.row.actualValue}
          </div>
        );
      },
    },
  ];

  return (
    <Box
      sx={{
        height: 400,
        width: "100%",
        maxWidth: "900px",
        margin: "20px auto",
        padding: "0px 25px",
      }}
    >
      <DataGridPro
        rows={activity ?? []}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        disableRowSelectionOnClick
        sx={{ minHeight: "700px" }}
      />
    </Box>
  );
};

export default ActivityLog;
