import React, { useState } from "react";

const formatNumber = (num) => {
  if (!num) return "";
  return num.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
};

const EditableFieldText = ({ value, row, api, onStateChange, field }) => {
  const [inputValue, setInputValue] = useState(formatNumber(value?.toString()));

  const handleChange = (e) => {
    let updatedValue = e.target.value;

    const numericValue = updatedValue.replace(/\./g, "");

    setInputValue(formatNumber(numericValue));

    api.setEditCellValue({
      id: row.id,
      field: field,
      value: numericValue,
    });
    onStateChange(numericValue);

    e.preventDefault();
  };

  return (
    <input
      type="text"
      value={inputValue}
      onChange={handleChange}
      style={{
        width: "100%",
        padding: "10px",
        outline: "none",
        border: "none",
      }}
    />
  );
};

export default EditableFieldText;
