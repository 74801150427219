import { Autocomplete, Box, Button, Menu, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import user from "../../../assets/icons/user-light.svg";
import { editBoardField } from "../../../utils/ApiCall";

const UsersSelect = ({ row, users, field, handleEditResponseChange }) => {
  let currentUrl = window.location.pathname;
  const [state, setState] = useState({
    anchorEl: null,
    open: false,
    windowWidth: window.innerWidth,
    selectedUsers: row.responsibles
      ? row?.responsibles
      : [{ username: row[field]?.username, id: row[field]?.id }] || [],
  });

  const isMobile = state.windowWidth <= 600;
  const cellRef = useRef(null);

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: null,
      open: false,
    }));
  };

  const handleClick = (event) => {
    setState((prevState) => ({
      ...prevState,
      anchorEl: event.currentTarget,
      open: true,
    }));
  };

  const handleAutocompleteChange = (event, newValue) => {
    setState((prevState) => ({
      ...prevState,
      selectedUsers: newValue,
    }));
  };

  const submitValue = state.selectedUsers?.map((selected) => selected?.id);
  let renderValue;
  if (field === "responsibles") {
    renderValue = row?.responsibles?.map((user) => user?.username).join(", ");
  } else if (field === "owner" && currentUrl === "/solicitud-campana") {
    renderValue = row[field]?.username;
  }

  const usersId = submitValue.map((userId) => {
    const user = users.find((u) => u.id === userId);
    return user || {}; // Manejar el caso en que no se encuentre el usuario
  });

  const cellStyle = {
    position: "relative",
  };

  const menuStyle = {
    overflow: "visible",
    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
    width: 500,
    height: isMobile ? 600 : "auto",
    overflowY: isMobile ? "scroll" : "",
    paddingRight: isMobile ? "20px" : "0px",
    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: 0,
      right: "49%",
      width: 10,
      height: 10,
      bgcolor: "background.paper",
      transform: "translateY(-50%) rotate(45deg)",
      zIndex: 0,
    },
  };

  const menuProps = {
    anchorEl: state.anchorEl,
    id: "account-menu",
    open: state.open,
    onClose: handleClose,
    PaperProps: {
      elevation: 0,
      sx: {
        ...menuStyle,
        marginLeft: isMobile ? "0px" : "190px",
      },
    },
    transformOrigin: { horizontal: "right", vertical: "top" },
    anchorOrigin: { horizontal: "right", vertical: "bottom" },
  };

  const handleSubmit = () => {
    editBoardField(currentUrl, [row.id], field, submitValue);
    handleEditResponseChange(state.selectedUsers, row.id, field);
    handleClose();
  };

  return (
    <Box>
      <p
        onClick={handleClick}
        ref={cellRef}
        style={cellStyle}
        className="cellStyle"
      >
        {row?.responsibles?.length === 0 || row[field] === null ? (
          <div
            style={{
              width: "120px",
              padding: "0 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={user} alt="Usuario" />
          </div>
        ) : (
          <div
            style={{
              width: "120px",
              padding: "0 10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>{renderValue}</span>
          </div>
        )}
      </p>
      <Menu {...menuProps}>
        {" "}
        <Autocomplete
          size="small"
          multiple
          disablePortal
          id="combo-box-demo"
          options={users}
          getOptionLabel={(user) => user?.username}
          sx={{
            width: "100%",
          }}
          onChange={handleAutocompleteChange}
          value={usersId}
          getOptionDisabled={(option) =>
            state.selectedUsers?.some((user) => user.id === option.id)
          }
          renderInput={(params) => (
            <TextField {...params} label="Usuarios" variant="standard" />
          )}
        />
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <Button sx={{ marginTop: "20px" }} onClick={handleSubmit}>
            Guardar
          </Button>
        </div>
      </Menu>
    </Box>
  );
};

export default UsersSelect;
