import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  createTheme,
  LinearProgress,
  Menu,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro";
import dayjs from "dayjs";
import "dayjs/locale/es";
import { editDate } from "../../../utils/ApiCall";

const theme = createTheme({
  components: {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: "50px",
          backgroundColor: "var(--blackLow)",
        },
        bar: {
          backgroundColor: "var(--done)",
        },
      },
    },
  },
});

const isMobile = window.innerWidth <= 600;

const Duration = ({ row, value, selectionRow }) => {
  const pathname = window.location.pathname.replace("/", "");
  const defaultStartDate = dayjs();
  const defaultEndDate = dayjs().endOf("month");

  const [dateRange, setDateRange] = useState({
    from: row?.dateStart || defaultStartDate.toDate(),
    to: row?.dateTo || defaultEndDate.toDate(),
  });
  const [menuAnchor, setMenuAnchor] = useState(null);

  const cellRef = useRef(null);

  useEffect(() => {
    setTimeout(window.unlock, 100);
  }, []);

  const handleDateChange = ([start, end]) => {
    const newFrom = start?.toDate();
    const newTo = dayjs(end?.toDate()).add(1, "day").toDate();

    setDateRange({ from: newFrom, to: newTo });

    if (start && end) {
      const selectedRows = selectionRow.length ? selectionRow : [row.id];
      editDate(pathname, start?.$d, end?.$d, selectedRows);
    }
  };

  const getProgress = () => {
    const { from, to } = dateRange;
    const startDate = dayjs(from);
    const endDate = dayjs(to);
    const now = dayjs();

    if (endDate.isBefore(now)) return 100;

    const total = endDate.diff(startDate, "day");
    const elapsed = now.diff(startDate, "day");
    return Math.round((elapsed / total) * 100);
  };

  const renderStartDate = dayjs(dateRange.from).locale("es").format("MMM D");
  const renderEndDate = dayjs(dateRange.to)
    .subtract(1, "day")
    .locale("es")
    .format("MMM D");

  const openMenu = (event) => setMenuAnchor(event.currentTarget);
  const closeMenu = () => setMenuAnchor(null);

  return (
    <div ref={cellRef} style={{ position: "relative", cursor: "pointer" }}>
      <ThemeProvider theme={theme}>
        <LinearProgress
          variant="determinate"
          value={getProgress()}
          sx={{ height: "25px", width: "120px", borderRadius: "50px" }}
        />
        <Box
          onClick={openMenu}
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "120px",
          }}
        >
          <Typography variant="caption" color="white">
            {row.dateStart && row.dateTo
              ? `${renderStartDate} - ${renderEndDate}`
              : "Sin duración"}
          </Typography>
        </Box>
      </ThemeProvider>
      <Menu
        anchorEl={menuAnchor}
        open={!!menuAnchor}
        onClose={closeMenu}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            marginLeft: isMobile ? "0px" : "212px",
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            defaultValue={[
              dayjs(dateRange.from),
              dayjs(dateRange.to).subtract(1, "day"),
            ]}
            disablePast
            onChange={handleDateChange}
          />
        </LocalizationProvider>
      </Menu>
    </div>
  );
};

export default Duration;
