import { useContext, useRef, useState } from "react";
import { UserContext } from "../../UserContext";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Breadcrumbs,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  handleDeleteNote,
  handleEditNote,
  handleSubmitNote,
} from "../../utils/ApiCall";
import NotesIcon from "@mui/icons-material/Notes";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Modal from "@mui/material/Modal";
import FileViewer from "../../components/global/FileViewer";
import CloseIcon from "@mui/icons-material/Close";
import { FileUploader } from "react-drag-drop-files";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";

import { saveAs } from "file-saver";
import styles from "../../styles/Threads.module.css";
import excelimage from "../../assets/icons/excel_v2.svg";
import pdfimage from "../../assets/icons/pdf_v2.svg";
import zipimage from "../../assets/icons/zip_v2.svg";
import wordimage from "../../assets/icons/word_v2.svg";
import ReactQuill from "react-quill";
import Swal from "sweetalert2";
import { Edit } from "@mui/icons-material";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CustomFileUploader from "../../components/global/CustomFileUploader";
import { fileTypes } from "../../json/FileTypes";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const Notes = ({
  id,
  notes,
  setNotes,
  backendUrl,
  imgRefs,
  contenidoRef,
  row,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  let pathname = window.location.pathname.replace("/", "");
  const { key, setKey } = useContext(UserContext);

  setKey(localStorage?.getItem("jwt"));

  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [addNote, setAddNote] = useState(false);
  const quillRef = useRef(null);
  const [expanded, setExpanded] = useState({});
  const [editNote, setEditNote] = useState(false);
  const [content, setContent] = useState();
  const [noteId, setNoteId] = useState();

  const handleUploadFile = async (file) => {
    const fileJoined = Array.from(files).concat(Array.from(file));
    setFiles(fileJoined);
    const newFiles = Array.from(file);
    const showFiles = selectedFile.concat(newFiles);
    setSelectedFile(showFiles);
  };

  const handleDelete = async (file) => {
    let files = selectedFile.filter(
      (e) => e.lastModified !== file.lastModified
    );
    setFiles(files);
    setSelectedFile(files);
  };

  const handleFormEditNote = async (note) => {
    setEditNote(true);
    setContent(note.content);
    setNoteId(note.id);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    let newNotes = await handleSubmitNote(
      key,
      files,
      pathname,
      id,
      value,
      title
    );
    setIsSubmitting(false);
    setValue("");
    setNotes(newNotes.data.notes);
    setFiles("");
    setSelectedFile([]);
    setTitle("");

    setExpanded({ 0: true });
  };

  const handleAddNote = () => {
    setAddNote(!addNote);
  };

  const handleChangePanel = (panel) => (event, newExpanded) => {
    setExpanded((prevExpanded) => ({
      ...prevExpanded,
      [panel]: newExpanded,
    }));
  };

  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");

  const handleImageClick = (e, file) => {
    e.preventDefault();
    setModalOpen(true);
    setModalImage(file);
  };

  const handleCloseModal = () => {
    setModalOpen(!modalOpen);
    setModalImage(null);
  };

  const handleDownload = async () => {
    const { url, name } = modalImage;
    const fileUrl = `${backendUrl}${url}`;
    saveAs(fileUrl, name);
  };

  const modules = useRef({
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
          { list: "ordered" },
          { list: "bullet" },
          { indent: "-1" },
          { indent: "+1" },
        ],
        ["link"],
        ["clean"],
      ],
    },
  }).current;

  const handleFormDeleteNote = (note) => {
    Swal.fire({
      title: "¿Quieres eliminar esta nota?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDeleteNote(key, pathname, row.id, note.id, setNotes);
        setExpanded({ 0: false });
      }
    });
  };

  const handleSubmitEdit = async (e) => {
    e.preventDefault();
    await handleEditNote(files, pathname, row.id, noteId, content, setNotes);
    setEditNote(false);
  };

  const handleCancelButton = () => {
    setEditNote(false);
  };

  function formatCreatedAt(createdAt) {
    const date = new Date(createdAt);
    const month = date.toLocaleString("default", {
      month: "long",
    });
    const day = date.getDate();
    const hour = date.getHours();
    const minutes = date.getMinutes();
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedDate = `${month} ${day}, ${hour}:${formattedMinutes}`;
    return formattedDate;
  }

  const handleInputTitle = (e) => {
    setTitle(e.target.value);
  };

  return (
    <div style={{ padding: "25px" }}>
      <Button
        sx={{
          textTransform: "none",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
        }}
        onClick={handleAddNote}
      >
        <NotesIcon sx={{ marginRight: "6px" }} />
        {pathname === "master-branding"
          ? "Agregar Costo/Reporte"
          : "Agregar Nota"}
      </Button>
      <Box
        component="form"
        onSubmit={(e) => handleSubmit(e)}
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            padding: "13px 0px",
            width: "100%",
          }}
        >
          {addNote && (
            <div style={{ width: "100%" }}>
              <TextField
                id="outlined-basic"
                label="Titulo"
                variant="outlined"
                className={styles.title}
                type="text"
                onChange={handleInputTitle}
                value={title}
                style={{ width: "100%", marginBottom: "20px" }}
              />
              <ReactQuill
                theme="snow"
                value={value}
                onChange={(newValue) => setValue(newValue)}
                sx={{ width: "100%" }}
                placeholder="Escribir nota"
                modules={modules}
                ref={quillRef}
              />

              <div
                style={{
                  marginTop: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <FileUploader
                  handleChange={handleUploadFile}
                  name="file"
                  types={fileTypes}
                  multiple={true}
                  label="Elegir archivos o arrastrar aqui"
                  hoverTitle="Arrastrar aqui"
                  children={<CustomFileUploader isMobile={isMobile} />}
                />

                <Button
                  disabled={isSubmitting}
                  type="submit"
                  sx={{
                    marginLeft: "12px",
                    backgroundColor: "var(--blueFour)",
                    color: "#fcfaff",
                    textTransform: "none",
                    padding: "4px 8px",
                    minWidth: "80px",
                    height: "40px",
                    fontSize: "16px",
                    "&:hover": {
                      backgroundColor: "var(--blue)",
                    },
                  }}
                >
                  {isSubmitting ? "Publicando" : "Publicar"}
                </Button>
              </div>
            </div>
          )}

          {selectedFile &&
            selectedFile.map((file) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p key={file.name}>{file.name}</p>
                <Button onClick={() => handleDelete(file)}>
                  <DeleteIcon style={{ width: "23px" }} />
                </Button>
              </div>
            ))}
        </div>
      </Box>
      <Box component="form" onSubmit={handleSubmitEdit}>
        {notes?.map((note, index) => (
          <div key={index} style={{ margin: "10px 0" }}>
            <Accordion
              expanded={expanded[index] || false}
              onChange={handleChangePanel(index)}
              sx={{ backgroundColor: "transparent" }}
            >
              <AccordionSummary
                aria-controls="panel2d-content"
                id="panel2d-header"
              >
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    zIndex: "1000",
                  }}
                >
                  <Typography>{note.title ? note.title : "Nota"}</Typography>
                </div>
              </AccordionSummary>
              <AccordionDetails sx={{ wordBreak: "break-word" }}>
                {editNote && +noteId === +note.id ? (
                  <div>
                    <ReactQuill
                      theme="snow"
                      value={content}
                      onChange={setContent}
                      placeholder="Contenido"
                      modules={modules}
                      ref={quillRef}
                    />

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        width: "100%",
                        marginTop: "20px",
                        marginBottom: note.media ? "20px" : "0px",
                      }}
                    >
                      <Button
                        color="primary"
                        sx={{
                          marginRight: "10px",
                          backgroundColor: "transparent",
                          color: "var(--lowBlack)",
                          textTransform: "none",
                          padding: "4px 8px",
                          minWidth: "80px",
                          height: "35px",
                          fontSize: "16px",
                          "&: hover": {
                            backgroundColor: "var(--blueLow)",
                          },
                        }}
                        onClick={handleCancelButton}
                      >
                        Cancelar
                      </Button>

                      <Button
                        type="submit"
                        color="primary"
                        sx={{
                          backgroundColor: "var(--blueFour)",
                          color: "#fcfaff",
                          textTransform: "none",
                          padding: "4px 8px",
                          minWidth: "80px",
                          height: "35px",
                          fontSize: "16px",
                          "&: hover": {
                            backgroundColor: "var(--blue)",
                          },
                        }}
                      >
                        Guardar
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <p
                      style={{ marginBottom: "15px", paddingTop: "10px" }}
                      dangerouslySetInnerHTML={{
                        __html: note.content,
                      }}
                    />

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <ScheduleIcon
                        style={{
                          marginRight: "6px",
                          color: "var(--gray)",
                          fontSize: "18px",
                        }}
                      />
                      <p className={styles.dateTitle}>
                        {formatCreatedAt(note.createdAt)}
                      </p>
                    </div>
                  </div>
                )}

                {!note.media && !editNote ? (
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip title="Editar nota">
                      <IconButton onClick={() => handleFormEditNote(note)}>
                        <Edit />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title="Eliminar nota">
                      <IconButton onClick={() => handleFormDeleteNote(note)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : null}

                {note.media &&
                  note.media.map((file, index) => {
                    if (
                      file.mime === "image/jpeg" ||
                      file.mime === "image/png" ||
                      file.mime === "image/bmp" ||
                      file.mime === "image/x-png" ||
                      file.mime === "image/gif" ||
                      file.mime === "video/mp4"
                    ) {
                      return (
                        <div
                          key={file.id}
                          style={{
                            position: "relative",
                          }}
                        >
                          <div key={file.id} style={{ marginBottom: "20px" }}>
                            <div ref={contenidoRef}>
                              <img
                                alt="img"
                                src={`${backendUrl}${file.url}`}
                                style={{
                                  maxHeight: "600px",
                                  maxWidth: "800px",
                                  cursor: "pointer",
                                }}
                                onLoad={(e) => {
                                  imgRefs.current[file.id] = e.target;
                                }}
                                onClick={(e) => handleImageClick(e, file)}
                              />
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Tooltip title="Editar nota">
                              <IconButton
                                onClick={() => handleFormEditNote(note)}
                              >
                                <Edit />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title="Eliminar nota">
                              <IconButton
                                onClick={() => handleFormDeleteNote(note)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div key={file.id}>
                          <div
                            style={{
                              width: "220px",
                              height: "132px",
                              backgroundColor:
                                file.mime ===
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                  ? "rgb(35, 127, 76)"
                                  : file.mime === "application/pdf"
                                  ? "rgb(250, 15, 0)"
                                  : file.mime === "application/zip"
                                  ? "rgb(255, 204, 0)"
                                  : file.mime === "text/html"
                                  ? "rgb(35, 104, 196)"
                                  : "",
                              borderRadius: "4px",
                              border: "1px solid #dee2ff",
                              position: "relative",
                              marginBottom: "40px",
                            }}
                            key={file.id}
                          >
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <img
                                src={
                                  file.mime ===
                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    ? excelimage
                                    : file.mime === "application/pdf"
                                    ? pdfimage
                                    : file.mime === "application/zip"
                                    ? zipimage
                                    : wordimage
                                }
                                alt=""
                                style={{
                                  height: "70%",
                                  width: "70%",
                                  objectFit: "contain",
                                  objectPosition: "center",
                                  cursor: "pointer",
                                }}
                                onClick={(e) => handleImageClick(e, file)}
                              />
                            </div>

                            <div style={{ width: "100%" }}>
                              <Tooltip title={file.name}>
                                <p
                                  style={{
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    margin: "0",
                                    maxWidth: "100%",
                                  }}
                                >
                                  {file.name}
                                </p>
                              </Tooltip>
                            </div>
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Tooltip title="Eliminar nota">
                              <IconButton
                                onClick={() => handleFormDeleteNote(note)}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    }
                  })}
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </Box>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        aria-labelledby="modal-title"
      >
        <>
          <div
            style={{
              width: "95vw",
              height: "82vh",
              backgroundColor: "#fcfaff",
              position: "relative",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -55%)",
              borderRadius: "25px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                padding: "20px",
                backgroundColor: "#fcfaff",
                borderBottom: "1px solid #dee2ff",
                borderTopLeftRadius: "16px",
                borderTopRightRadius: "16px",
              }}
            >
              <ImageOutlinedIcon
                sx={{ color: "var(--blue)", fontSize: "50px" }}
              />
              <div style={{ marginLeft: "8px" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <p style={{ fontWeight: "600" }}>
                    {modalImage?.url?.split("/").slice(-1)}
                  </p>
                  <CloudDownloadOutlinedIcon
                    onClick={handleDownload}
                    className={styles.downloadIcon}
                  />
                </div>
                <div role="presentation">
                  <Breadcrumbs aria-label="breadcrumb" separator="›">
                    <div underline="hover" color="inherit" href="/" disabled>
                      {pathname}
                    </div>
                  </Breadcrumbs>
                </div>
              </div>
            </div>
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
              onClick={handleCloseModal}
            >
              <IconButton>
                <CloseIcon />
              </IconButton>
            </div>
            <FileViewer url={`${backendUrl}${modalImage?.url}`} />
          </div>
        </>
      </Modal>
    </div>
  );
};

export default Notes;
