import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import container from "../../styles/Global.module.css";
import GoogleButton from "react-google-button";

import { useEffect } from "react";

const Landing = () => {
  const isLoggedIn = () => {
    return !!localStorage.getItem("jwt");
  };
  const [isLogged, setIsLogged] = useState(isLoggedIn());
  const navigate = useNavigate();

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  const LoginButton = () => (
    <a href={`${backendUrl}/api/connect/google`}>
      <GoogleButton type="light" label="Ingresar con Google" />
    </a>
  );

  const LogoutButton = (props) => (
    <GoogleButton type="light" label="Salir" onClick={props.onClick} />
  );

  const logout = (e) => {
    e.preventDefault();
    localStorage.removeItem("jwt");
    localStorage.removeItem("username");
    localStorage.removeItem("email");
    localStorage.removeItem("id");
    localStorage.removeItem("session");
    localStorage.removeItem("role");
    setIsLogged(false);
    navigate(`/`, { replace: true });
  };

  useEffect(() => {
    if (isLoggedIn()) {
      navigate("/home");
    }
  }, [navigate]);

  return (
    <div>
      <div className={container.homeContainer} style={{ height: "100vh" }}>
        <div>
          <img src="./ionvo.png" alt="ionvo" />
          <div className={container.homeLoginButton}>
            {isLogged ? <LogoutButton onClick={logout} /> : <LoginButton />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Landing;
