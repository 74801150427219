import React from "react";
import Swal from "sweetalert2";

const Cap = ({ value, row, api, onCapChange, onTimeframeChange }) => {
  const handleChange = (e) => {
    if (!row.timeframe && !row.cap_total_offer_timeframe) {
      Swal.fire("Primero se debe cargar un timeframe", "", "error");
      return;
    }

    const updatedValue = e.target.value;

    api.setEditCellValue({
      id: row.id,
      field: "cap",
      value: updatedValue,
    });

    onCapChange(updatedValue);
  };

  return (
    <input
      type="number"
      value={value}
      onChange={handleChange}
      style={{
        width: "100%",
        padding: "10px",
        outline: "none",
        border: "none",
      }}
    />
  );
};

export default Cap;
